const conditionalRowStyles = [
	{
		when: (row: any) => row.toggleSelected,
		style: {
			backgroundColor: '#f47304',
		},
	},
	{
		when: (row: any) => !row.toggleSelected,
		style: {
			backgroundColor: 'transparent',
		},
	},
	{
		when: (row: any) => row.statusCampaign === 'active' && row.stats && row.stats.calling === 0,
		style: {
			backgroundColor: 'rgba(255, 0, 0, 0.05)',
		},
	},
];
export default conditionalRowStyles;
