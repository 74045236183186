import format from 'format-number';

export const formatNumber = (number: number) => {
	const valueFormated = format({
		decimalsSeparator: ',',
		round: 0,
	})(number, {});
	return valueFormated;
};

export const convertRoleType = (label: string) => {
	switch (label) {
		case 'Company supervisor':
			return 'company_supervisor';
		case 'Supervisor':
			return 'company_supervisor';
		case 'Customer':
			return 'customer';
		case 'Cliente':
			return 'customer';
		case 'root':
			return 'root';
		default:
			return 'customer';
	}
};
export const calculateTotalAniNumbers = (aniNumbers: { [key: string]: number[] }[]): number => {
	let total = 0;

	if (aniNumbers.length > 0) {
		aniNumbers.forEach(state => {
			for (let key in state) {
				if (Array.isArray(state[key])) {
					state[key].forEach(() => {
						total += 1;
					});
				}
			}
		});
		return total;
	} else {
		return 0;
	}
};

interface ProductDetails {
	_id: string;
	name: string;
	inventory: number;
	sku: string;
	status: boolean;
	__v: number;
}

interface Product {
	productId: string;
	quantity: number;
	price: number;
	_id: string;
	productDetails: ProductDetails;
}

export function calculateTotalPrice(products: Product[]): number {
	return products.reduce((total, product) => {
		return total + product.quantity * product.price;
	}, 0);
}
export function getProductNames(products: Product[]): string {
	const productNamesWithQuantities = products.map(
		product => `${product.productDetails.name} x${product.quantity}`
	);
	const lastProductNameWithQuantity = productNamesWithQuantities.pop();
	return productNamesWithQuantities.length
		? productNamesWithQuantities.join(', ') + ' y ' + lastProductNameWithQuantity
		: lastProductNameWithQuantity || '';
}
