const messages_en = {
	'login.welcome': 'Welcome, sign up',
	'logout.title': 'Logout',

	'commons.back': 'Back',
	'commons.save': 'Save',
	'commons.validate': 'Validate',
	'commons.update': 'Update',
	'commons.rowsPerPage': 'Rows per page',
	'commons.of': 'of',
	'commons.noData': 'Data not found',
	'commons.step': 'Step',
	'commons.filter': 'Filter...',
	'commons.dragAndDrop': 'Click or drag and drop the file here',
	'commons.findNumber': 'Find number',
	'commons.initDate': 'Init date',
	'commons.endDate': 'End date',
	'commons.downloadAll': 'Download all',
	'commons.consolidated': 'Consolidated',
	'commons.status': 'Status',
	'commons.apply': 'Apply',
	'commons.credits': 'Credits',
	'commons.next': 'Next',
	'commons.download': 'Download',
	'commons.have': 'You have',
	'commons.error': 'error(s)',
	'commons.required': 'Required field',
	'commons.textQuantity': 'Number of characters:',
	'commons.createAudio': 'Create audio',
	'commons.launch': 'Launch',
	'commons.start': 'Start',
	'commons.daily': 'Daily',
	'commons.weekly': 'Weekly',
	'commons.biweekly': 'Biweekly',
	'commons.monthly': 'Monthly',
	'commons.company_supervisor': 'Company supervisor',
	'commons.customer': 'Customer',
	'commons.downloading': 'Downloading',
	'commons.root': 'root',
	'commons.add': 'Add',
	'commons.insert': 'Insert',
	'commons.delete': 'Delete',
	'commons.today': 'Today',
	'commons.historic': 'Historic',

	'statusCampaign.active': 'Active',
	'statusCampaign.paused': 'Paused',
	'statusCampaign.onHold': 'On hold',
	'statusCampaign.toClone': 'To clone',
	'statusCampaign.cloning': 'Cloning',

	'blaster.type.normal': 'Normal',
	'blaster.type.press': 'Press',

	'month.enero': 'January',
	'month.febrero': 'February',
	'month.marzo': 'March',
	'month.abril': 'April',
	'month.mayo': 'May',
	'month.junio': 'June',
	'month.julio': 'July',
	'month.agosto': 'August',
	'month.septiembre': 'September',
	'month.octubre': 'October',
	'month.noviembre': 'November',
	'month.diciembre': 'December',

	'table.name': 'Name',
	'table.nameCampaign': 'Campaign name',
	'table.nameBlacklist': 'Blacklist name',
	'table.user': 'User',
	'table.mail': 'Mail',
	'table.company': 'Company',
	'table.role': 'Role',
	'table.credits': 'Credits',
	'table.status': 'Status',
	'table.user.status': 'Status of user',
	'table.company.status': 'Status of company',
	'table.options': 'Options',
	'table.createDate': 'Creation date',
	'table.maxTps': 'Maximum TPS',
	'table.previewer': 'Audio',
	'table.records': 'Records',
	'table.number': 'Number',
	'table.operation': 'Operation',
	'table.server': 'Server',
	'table.description': 'Description',
	'table.serial': 'Serial number',
	'table.dataCenter': 'Data center',
	'table.list': 'List',
	'table.init': 'Init',
	'table.end': 'End',
	'table.success': 'Success',
	'table.error': 'Error',
	'table.onHold': 'On hold',
	'table.invalid': 'Invalid',
	'table.press': 'Press',
	'table.registers': 'Total registers',
	'table.blacklist': 'Blacklist',
	'table.priority': 'Priority',
	'table.calling': 'Calling',
	'table.showInactive': 'Show inactive',
	'table.totalRegisters': 'Total contacts',
	'table.campaignType': 'Campaign type',
	'table.blasterType': 'Blaster type',
	'table.audio': 'Audio name',
	'table.updateDate': 'Update date',
	'table.assignedNumbers': 'Assigned numbers',
	'table.initTime': 'Init time',
	'table.endTime': 'End time',
	'table.deliveryTime': 'Time zone',
	'table.timeZoneTitle': 'Time zone',
	'opt.delete': 'Delete',
	'opt.edit': 'Edit',
	'opt.credits': 'Add credits',
	'opt.downloadReport': 'Download report',
	'opt.downloadAudio': 'Download audio',
	'opt.downloadFile': 'Download file',
	'opt.clone': 'Clone campaign',
	'table.contactation': 'Contactation',
	'table.userToReport': 'Usuario a reportar',
	'table.period': 'Periodicity',
	'table.reportType': 'Report type',
	'table.mainMail': 'Main mail',
	'table.mailList': 'Mail list',
	'table.module': 'Module',
	'table.details': 'Details',

	'dashboard.title': 'Dashboard',
	'dashboard.currentCampaign': 'Ongoing campaigns',
	'dashboard.onholdCampaign': 'Campaigns on hold',
	'dashboard.pausedCampaign': 'Paused campaigns',
	'dashboard.callsmadeCampaign': 'Calls made',
	'dashboard.successcallsCampaign': 'Satisfactory calls',
	'dashboard.contactpercentageCampaign': 'Contact percentage',
	'dashboard.campaignsStatus': 'Status of the campaigns',

	'users.title': 'Users',
	'users.welcome': 'These are your registered users:',
	'users.add': 'Add user',
	'users.edit': 'Edit user',
	'users.credits': 'Add credits',
	'users.form.name': 'Username',
	'users.form.email': 'E-mail',
	'users.form.userType': 'User type',
	'users.form.password': 'Password',
	'users.form.confirmPassword': 'Confirm password',
	'users.form.updatePassword': 'Do you want to update the password?',
	'users.form.company': 'Select a company',
	'users.form.role': 'Select a role',
	'users.form.prefix': 'Perimeta client prefix',
	'users.form.prefixWord': 'Prefix',
	'users.form.credits': 'Credits',
	'users.form.currentCredits': 'Current credits',
	'users.form.aniDefault': 'ANI default number',
	'users.form.prefixPbx': 'VDI/DID for transfer',
	'users.form.ivr': 'User have IVR',
	'users.form.ivrKey': 'IVR key',
	'users.form.countryCode': 'Select a country code',
	'users.form.limitCredits': 'Limit credits',
	'users.form.hasVariableTTS': 'User has variable TTS',
	'users.form.hasNeuronalTTS': 'User has neuronal TTS',
	'users.form.deliveryTime': 'Time zone',

	'company.title': 'Companies',
	'company.welcome': 'These are your registered companies:',
	'company.add': 'Add company',
	'company.edit': 'Edit company',
	'company.form.name': 'Company name',
	'company.form.maxTps': 'Maximum TPS',
	'company.form.callerId': 'Caller ID',
	'company.form.internationalCalls': 'International calls',

	'campaigns.title': 'Campaigns',
	'campaigns.welcome': 'These are your active campaigns:',
	'campaigns.add': 'Add campaign',
	'campaigns.edit': 'Edit campaig',
	'campaigns.noCredits':
		'Sorry, you do not have enough credits to create a campaign, please contact your sales agent for more credits.',
	'campaigns.maxErrors':
		'Sorry, you must have at least 1 valid number to set up the campaign, try again with another file.',
	'camp.nav.identification': 'Identification',
	'camp.nav.contacts': 'Contacts',
	'camp.nav.message': 'Message',
	'camp.nav.configuration': 'Configuration',
	'camp.nav.launch': 'Launch',
	'camp.welcome.title': "Let's get started!",
	'camp.welcome.description':
		'Give a name to your campaign, it will help you to identify it later.',
	'camp.contact.toltip1':
		"Allows uploading files in CSV/Excel format, maximum 1'048,575 records and 6 columns per file.",
	'camp.contactp2.description': 'In the list you can see the first 5 records of your archive',
	'camp.contactp2.tooltip1': 'Allows you to select the telephone number column to be dialed.',
	'camp.contactp2.tooltip2': 'Data column to be displayed when downloading the final report.',
	'camp.contactp2.tooltip3': 'Excludes duplicate records, to avoid double marking.',
	'camp.contactp2.tooltip4': 'Disables the header row exclusion function. Default: Active',
	'camp.contactp2.tooltip5':
		'Validation of upload file rules: Validation of telephone numbers according to country code. Validation of special characters',
	'camp.contactp2.tooltip6': 'Upload file validation rules validation report output and download.',
	'campaigns.clone.title': 'Clone campaign',

	'audiosList.title': 'List of audios',
	'audiosList.description': 'Select an audio that you have previously saved in your audio library.',
	'upload.title': 'Upload audio',

	'api.title': 'API',
	'api.welcome': 'These are your active API campaigns:',

	'txt2speech.title': 'Text to speech',
	'txt2speech.voiceId': 'Select voice type',
	'txt2speech.instructions': 'Type the text to be converted to voice:',

	'txt2speechVar.title': 'Text to speech with variables',
	'txt2speechVar.voiceId': 'Select voice type',
	'txt2speechVar.instructions':
		'Type the text that will be converted to voice, you can enter variables in your text, the audio sample will be heard with the first result of your contact file:',
	'txtToSpeechVar.normal': 'Text',
	'txtToSpeechVar.tel': 'Phone',
	'txtToSpeechVar.date': 'Date',

	'camp.form.name': 'Name',
	'camp.form.columnNumber': 'Select number column',
	'camp.form.campAux': 'Select auxiliary field',
	'camp.form.eliminateRepeat': 'Delete repeats',
	'camp.form.hasHeaders': 'File has headers',
	'camp.form.validateFile': 'Validate file',

	'camp.form.attempts': 'Attemptss',
	'camp.form.attempts.tooltip': 'Number of unsuccessful attempts to contact records.',
	'camp.form.audioReproductions': 'Number of audio reproductions:',
	'camp.form.audioReproductions.tooltip':
		'Number of audio repetitions in an answered call. Options: 1,2,3 repetitions',
	'camp.form.riningTime': 'Ringing time',
	'camp.form.riningTime.tooltip': 'Adjustment of the ringing time.',
	'camp.form.riningTimeDuration': 'Maximum 1 minute',
	'camp.form.callDuration': 'Call duration',
	'camp.form.callDuration.tooltip': 'Setting the duration of an answered and transferred call.',
	'camp.form.callMaxTime': 'Maximum 5 minutes',
	'camp.form.voiceMail': 'Voicemail detection',
	'camp.form.voiceMail.tooltip':
		'Call cut-off function when a voice mailbox is detected. Default: Disabled',
	'camp.form.leaveVoiceMail': 'Leave message on voicemail',
	'camp.form.leaveVoiceMail.tooltip': 'Full audio release to voicemail function. Default: Disabled',
	'camp.form.conactPercentage': 'Contact percentage',
	'camp.form.conactPercentage.tooltip': 'Percentage of Records Successfully dialed. Default: 100%',
	'camp.form.blasterType': 'Blaster type',
	'camp.form.blasterType.tooltip':
		'Dialing options. Flat: Sends message without interaction Press: Redirects call to DID or Assigned IP.',
	'camp.form.velocityMode': 'Speed mode',
	'camp.form.velocityMode.tooltip':
		'Campaign execution speed mode. In case the campaign is of normal type, it will always be automatic.',
	'camp.form.velocityMode.opt.automatic': 'Automatic',
	'camp.form.priority': 'Priority',
	'camp.form.priority.tooltip':
		'Campaign execution speed. If the mode is automatic it will be fixed at maximum speed.',
	'camp.form.priority.opt': 'Maximum',
	'camp.form.tps.tooltip': 'Speed of campaign execution.',
	'camp.form.pressElection': 'Choose the number of press',
	'camp.form.pressElection.tooltip': 'Digit that transfers the call to the Call Center.',
	'camp.form.headerCallerId': 'Caller ID in header',
	'camp.form.headerCallerId.tooltip':
		'Information that will be displayed to the telephone executive. Default: Enabled',
	'camp.form.internationalCalls': 'International calls',

	'launch.campaign': 'campaign',
	'launch.totalContacts': 'Total contacts:',
	'launch.totalErrors': 'Total errors:',
	'launch.removeData': 'Remove duplicates and errors:',
	'launch.attempts': 'Attempts:',
	'launch.riningTime': 'Ringing time:',
	'launch.durationCall': 'Call duration:',
	'launch.voiceMail': 'Voicemail detection:',
	'launch.leaveVoiceMail': 'Leave message on voicemail:',
	'launch.audioReproductions': 'Audio reproductions:',
	'launch.init': 'Start:',
	'launch.end': 'End:',
	'launch.priority': 'Priority:',
	'launch.basterType': 'Blaster type:',
	'launch.pressElection': 'Press election:',

	'ivr.title': 'IVR',
	'ivr.welcome': 'These are your active IVR campaigns:',
	'ivr.add': 'Add IVR campaign',
	'ivr.noCredits':
		'Sorry, you do not have enough credits to create a campaign, please contact your sales agent for more credits.',
	'ivr.nav.identification': 'Identification',
	'ivr.nav.contacts': 'Contacts',
	'ivr.nav.message': 'Message',
	'ivr.nav.configuration': 'Configuration',
	'ivr.nav.launch': 'Launch',
	'ivr.welcome.title': "Let's get started!",
	'ivr.welcome.description': 'Give a name to your campaign, it will help you to identify it later.',
	'ivr.contact.toltip1':
		"Allows uploading files in CSV/Excel format, maximum 1'048,575 records and 6 columns per file.",
	'ivr.contactp2.description': 'In the list you can see the first 5 records of your archive',
	'ivr.contactp2.tooltip1': 'Allows you to select the telephone number column to be dialed.',
	'ivr.contactp2.tooltip2': 'Data column to be displayed when downloading the final report.',
	'ivr.contactp2.tooltip3': 'Excludes duplicate records, to avoid double marking.',
	'ivr.contactp2.tooltip4': 'Disables the header row exclusion function. Default: Active',
	'ivr.contactp2.tooltip5':
		'Validation of upload file rules: Validation of telephone numbers according to country code. Validation of special characters',
	'ivr.contactp2.tooltip6': 'Upload file validation rules validation report output and download.',

	'ivr.form.name': 'Name',
	'ivr.form.columnNumber': 'Select number column',
	'ivr.form.campAux': 'Select auxiliary field',
	'ivr.form.eliminateRepeat': 'Delete repeats',
	'ivr.form.hasHeaders': 'File has headers',
	'ivr.form.validateFile': 'Validate file',
	'ivr.form.attempts': 'Attemptss',
	'ivr.form.attempts.tooltip': 'Number of unsuccessful attempts to contact records.',
	'ivr.form.audioReproductions': 'Number of audio reproductions:',
	'ivr.form.audioReproductions.tooltip':
		'Number of audio repetitions in an answered call. Options: 1,2,3 repetitions',
	'ivr.form.riningTime': 'Ringing time',
	'ivr.form.riningTime.tooltip': 'Adjustment of the ringing time.',
	'ivr.form.riningTimeDuration': 'Maximum 1 minute',
	'ivr.form.callDuration': 'Call duration',
	'ivr.form.callDuration.tooltip': 'Setting the duration of an answered and transferred call.',
	'ivr.form.callMaxTime': 'Maximum 5 minutes',
	'ivr.form.voiceMail': 'Voicemail detection',
	'ivr.form.voiceMail.tooltip':
		'Call cut-off function when a voice mailbox is detected. Default: Disabled',
	'ivr.form.leaveVoiceMail': 'Leave message on voicemail',
	'ivr.form.leaveVoiceMail.tooltip': 'Full audio release to voicemail function. Default: Disabled',
	'ivr.form.blasterType': 'Blaster type',
	'ivr.form.blasterType.tooltip':
		'Dialing options. Flat: Sends message without interaction Press: Redirects call to DID or Assigned IP.',
	'ivr.form.velocityMode': 'Speed mode',
	'ivr.form.velocityMode.tooltip':
		'Campaign execution speed mode. In case the campaign is of normal type, it will always be automatic.',
	'ivr.form.velocityMode.opt.automatic': 'Automatic',
	'ivr.form.priority': 'Priority',
	'ivr.form.priority.tooltip':
		'Campaign execution speed. If the mode is automatic it will be fixed at maximum speed.',
	'ivr.form.priority.opt': 'Maximum',
	'ivr.form.tps.tooltip': 'Speed of campaign execution.',
	'ivr.form.pressElection': 'Choose the number of press',
	'ivr.form.pressElection.tooltip': 'Digit that transfers the call to the Call Center.',
	'ivr.form.headerCallerId': 'Caller ID in header',
	'ivr.form.headerCallerId.tooltip':
		'Information that will be displayed to the telephone executive. Default: Enabled',
	'ivr.form.internationalCalls': 'International calls',

	'audios.title': 'Audios',
	'audios.welcome': 'These are your active audios:',
	'audios.add': 'Add audio',
	'audios.intro':
		'Administration of audios, adding, dropping and deleting. Allowed formats: MP3, MP4, WAV, GSM, OGG',
	'audios.form.audioName': 'Audio name',

	'blacklists.title': 'Blacklists',
	'blacklists.welcome': 'These are your blacklists:',
	'blacklists.add': 'Add blacklist',
	'blacklists.edit': 'Edit blacklist',
	'blacklists.file': 'File',
	'blacklists.phoneNumber': 'Phone number',
	'blacklists.form.name': 'Name of the blacklist',
	'blacklists.form.user': 'Select a user',
	'blacklists.edit.addNumber': 'Add number',
	'blacklists.edit.addToolTip':
		'Single or burst (File) registration of telephone numbers, maximum 1 million.',
	'blacklists.edit.descriptionAdd': 'Add up to 10 numbers manually',
	'blacklists.edit.descriptionAddFile':
		"Add any number of numbers by means of a csv/xlsx file, it must contain a header named 'number'.",
	'blacklists.edit.descriptionDeleteFile':
		"Delete any number of numbers by means of a csv/xlsx file, it must contain a header named 'number'.",
	'blacklists.edit.deleteNumber': 'Delete number',
	'blacklists.edit.deleteDescription': 'Eliminates up to 10 numbers manually',
	'blacklists.edit.deleteToolTip':
		'Unitary or burst deregistration (Archive) of telephone numbers, maximum 1 million.',
	'blacklists.table.title': 'Numbers in blacklist',
	'blacklists.table.tooltip': 'Query and deletion of numbers in a unitary way.',
	'blacklists.log.tooltip': 'Displays the history of the activity in this functionality',

	'servers.title': 'Servers',
	'servers.welcome': 'These are your servers:',
	'servers.add': 'Add server',
	'servers.edit': 'Edit server',
	'servers.form.serverName': 'Server name',
	'servers.form.serverIP': 'Server IP',
	'servers.form.serialNumber': 'Serial number',
	'servers.form.dataCenter': 'Data center',
	'servers.form.description': 'Description',

	'ani.title': 'ANI list',
	'ani.title2': 'Lists of caller IDs',
	'ani.welcome': 'These are your ANI lists:',
	'ani.add': 'Add ANI list',
	'ani.edit': 'Edit ANI list',
	'ani.form.listName': 'Name of the list',
	'ani.form.user': 'Selet a user',
	'ani.form.listType': 'Type of list',

	'reports.title': 'Reports',
	'reports.welcome': 'These are your reports:',
	'reports.auto.open': 'Automate reporting',
	'reports.auto.tooltip':
		'Enable automated reporting to facilitate performance measurement of each campaign.',
	'reports.auto.title': 'Configuration of automatic reports',
	'reports.auto.description':
		'If you do not set up a mailing list, it will only be sent to the e-mail address registered in your user name',
	'reports.auto.form.period': 'Perioricity',
	'reports.auto.form.email': 'E-mail',
	'reports.accumulated': 'Accumulated report',
	'reports.accumulated.tooltip':
		'Download the general information of the selected campaigns by period, with the total number of registrations, successful calls and contact percentages.',

	'title.grapic.callsGroupedByStatus': 'calls grouped by status',
	'column-count-exceeded': 'Sorry, your file cannot contain more than 6 columns',

	'logs.title': 'Logs',

	'settings.title': 'Settings',

	'chart[success]': 'Success',
	'chart[error]': 'Error',
	'chart[onHold]': 'On hold',
	'chart[Invalid status]': 'Invalid',
	'chart[Press]': 'Press',
	'chart[blacklist]': 'Blacklist',
	'chart[calling]': 'Calling',
	'chart.pie.title': 'Chart of calls made by state',
	'chart.table.title': 'Contact list by state',

	'automatedReports.title': 'Automated reports',
	'automatedReports.add': 'Add report',
	'automatedReports.edit': 'Edit report',
	'automatedReports.form.perioricity': 'Perioricity',
	'automatedReports.form.type': 'Report type',
	'automatedReports.form.user': 'User',
	'automatedReports.form.mailList': 'Mail list',
	'automatedReports.form.username': 'Username',
	'automatedReports.form.password': 'Password',
	'automatedReports.form.host': 'Host',
	'automatedReports.form.port': 'Port',
	'automatedReports.form.secure': 'Secure FTP/SFTP',

	'logs.recordName': 'Record name',
	'logs.recordId': 'Record ID',
	'logs.oldStatusCampaign': 'Old status campaign',
	'logs.statusCampaign': 'Uopdated status',
	'logs.userName': 'User name',
	'logs.oldValue': 'Old value',
	'logs.value': 'Updated value',

	'timeZones.title': 'Time Zones',
	'timeZones.add': 'Add time zone',
	'timeZones.edit': 'Edit time zone',
	'timeZones.success': 'Time zone saved successfully',
	'timeZones.form.title': 'Time zone title',
	'timeZones.form.initTime': 'Initial time',
	'timeZones.form.endTime': 'End time',
};
export default messages_en;
