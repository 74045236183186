import { Routes, Route, Navigate } from 'react-router-dom';
import ScrollToTop from 'utils/scrollToUp';
import Layout from 'layout';
import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import Products from 'pages/products';
import ProductAdd from 'pages/products/add';
import ProductEdit from 'pages/products/edit';
import OrderStatus from 'pages/orderStatus';
import OrderStatusAdd from 'pages/orderStatus/add';
import OrderStatusEdit from 'pages/orderStatus/edit';
import PaymentMethods from 'pages/paymentMethods';
import PaymentMethodsAdd from 'pages/paymentMethods/add';
import PaymentMethodsEdit from 'pages/paymentMethods/edit';
import Orders from 'pages/orders';
import OrderAdd from 'pages/orders/add';
import OrderEdit from 'pages/orders/edit';
const Router = () => {
	return (
		<>
			<ScrollToTop />
			<Routes>
				<Route element={<Layout />}>
					<Route
						path="/"
						element={<Dashboard />}
					/>
					<Route
						path="/orders"
						element={<Orders />}
					/>
					<Route
						path="/orders/add"
						element={<OrderAdd />}
					/>
					<Route
						path="/orders/edit/:id"
						element={<OrderEdit />}
					/>
					<Route
						path="/products"
						element={<Products />}
					/>
					<Route
						path="/products/add"
						element={<ProductAdd />}
					/>
					<Route
						path="/products/edit/:id"
						element={<ProductEdit />}
					/>
					<Route
						path="/order-status"
						element={<OrderStatus />}
					/>
					<Route
						path="/order-status/add"
						element={<OrderStatusAdd />}
					/>
					<Route
						path="/order-status/edit/:id"
						element={<OrderStatusEdit />}
					/>
					<Route
						path="/payment-methods"
						element={<PaymentMethods />}
					/>
					<Route
						path="/payment-methods/add"
						element={<PaymentMethodsAdd />}
					/>
					<Route
						path="/payment-methods/edit/:id"
						element={<PaymentMethodsEdit />}
					/>
				</Route>
				<Route
					path="login"
					element={<Login />}
				/>
				<Route
					path="*"
					element={<Navigate to="/" />}
				/>
			</Routes>
		</>
	);
};
export default Router;
