import styled from 'styled-components';
export const MyButton = styled.button`
	align-self: center;
	width: 40px;
	height: 40px;
	font-weight: 700;
	background-color: red;
	color: #fff;
	border-radius: 50px;
	transition: all 0.2s ease-in;
	border: none;
	cursor: pointer;
	&:hover {
		color: #fff;
		background-color: #114175;
	}
	&:disabled {
		opacity: 0.5;
		&:hover {
			background-color: red;
		}
	}
`;
