const buildFormData = (formData: FormData, data: any, parentKey?: string) => {
	if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
		if (Array.isArray(data) && data.some(value => value instanceof File)) {
			for (let i = 0; i < data.length; i++) {
				buildFormData(formData, data[i], parentKey);
			}
		} else {
			Object.keys(data).forEach(key => {
				buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
			});
		}
	} else if (data) {
		formData.append(parentKey || '', data);
	}
	return formData;
};

const createFormData = (values: any) => {
	const formData = new FormData();
	buildFormData(formData, values);
	return formData;
};

export default createFormData;
