import { MainTitle } from 'common/text';
import { Content } from 'common/containers';
import OrdersTable from 'components/tables/orders';
import AddButton from 'common/buttons/add';

const Orders = () => {
	return (
		<>
			<MainTitle>
				<h1>Ordenes</h1>
				<AddButton url={`/orders/add`} />
			</MainTitle>
			<Content>
				<OrdersTable />
			</Content>
		</>
	);
};
export default Orders;
