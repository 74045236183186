import { useState, useEffect } from 'react';
import api from 'services/api';
import { useIntl } from 'react-intl';
const useAsyncSelect = (
	url: string,
	searchedValue: string,
	searchedLabel: string,
	isTranslated?: boolean,
	allOption?: boolean
) => {
	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [options, setOptions] = useState<any>([]);
	const transformToSelect = (data: any) => {
		const selectValues = data
			.map((item: any) => {
				if (item[searchedValue] !== 'root' && item[searchedLabel] !== 'root') {
					if (url === '/delivery-times') {
						let label = isTranslated
							? formatMessage({ id: `commons.${item[searchedLabel]}` })
							: `${item[searchedLabel]}: ${item.initTime} - ${item.endTime}`;
						return {
							value: item[searchedValue],
							label: label,
						};
					} else {
						let label = isTranslated
							? formatMessage({ id: `commons.${item[searchedLabel]}` })
							: item[searchedLabel];
						return {
							value: item[searchedValue],
							label: label,
						};
					}
				}
			})
			.filter((item: any) => item !== undefined);
		if (allOption) {
			selectValues.unshift({ value: '', label: 'Todos' });
		}
		return selectValues;
	};

	const performFetch = async () => {
		try {
			setIsLoading(true);
			const response = await api.get(`${url}?pagination=false&status=true`);
			if (response.data.docs.length === 0) {
				setIsLoading(false);
				setOptions([{ value: 'No hay opciones', label: 'No hay opciones' }]);
			} else {
				const transformedData = transformToSelect(response.data.docs);
				setIsLoading(false);
				setOptions(transformedData);
			}
		} catch (error) {
			setOptions([{ value: 'No hay opciones', label: 'No hay opciones' }]);
		}
	};
	useEffect(() => {
		performFetch();
	}, [url]);
	return { isLoading, options };
};
export default useAsyncSelect;
