import { MainTitle } from 'common/text';
import { Content } from 'common/containers';
import ProductsTable from 'components/tables/products';
import AddButton from 'common/buttons/add';

const Products = () => {
	return (
		<>
			<MainTitle>
				<h1>Productos</h1>
				<AddButton url={`/products/add`} />
			</MainTitle>
			<Content>
				<ProductsTable />
			</Content>
		</>
	);
};
export default Products;
