import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import 'utils/yupExtensions';
import { useIntl } from 'react-intl';
import useManageForms from 'hooks/useManageForms';
// inputs
import Input from 'components/forms/inputs/input';
import Submit from 'components/forms/inputs/submit';
const AddOrderStatusForm = () => {
	const { formatMessage } = useIntl();
	const { submit } = useManageForms();
	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
			color: '#ffffff',
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Este campo es obligatorio'),
			color: Yup.string().required('Este campo es obligatorio'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await submit(
				values,
				'/order-status',
				'Estatus de orden añadido correctamente',
				'/order-status'
			);
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				{/* basic fields */}
				<Grid $columns={1}>
					<Input
						nameData="name"
						placeholderData="Nombre del estatus de orden"
						typeData="text"
						label="Nombre del estatus de orden"
					/>
					<Input
						nameData="description"
						placeholderData="Descripción de el estatus de orden"
						typeData="text"
						label="Descripción del estatus de orden"
					/>
					<Input
						nameData="color"
						placeholderData="Color del estatus de orden"
						typeData="color"
						label="Color del estatus de orden"
					/>
				</Grid>

				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.save' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default AddOrderStatusForm;
