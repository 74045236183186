import { MyButton, MyLink } from './styles';

const Layout = (props: any) => {
	return (
		<>
			{!props.href && (
				<MyButton
					disabled={props.disabled}
					{...props}>
					{props.children}
				</MyButton>
			)}
			{props.href && (
				<MyLink
					{...props}
					to={props.href}>
					{props.children}
				</MyLink>
			)}
		</>
	);
};

export default Layout;
