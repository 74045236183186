import { Wrap, DataWrap, LoginWrap } from './styles';
const Layout = (props: any) => {
	const { children } = props;
	return (
		<Wrap>
			<DataWrap>
				<LoginWrap>
					{/* <img
						src={`/${process.env.REACT_APP_LOGO}`}
						alt={process.env.REACT_APP_NAME}
					/> */}
					{children}
				</LoginWrap>
			</DataWrap>
		</Wrap>
	);
};

export default Layout;
