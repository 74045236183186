import styled from 'styled-components';

export const Wrap = styled.div`
	.szh-menu__item {
		padding: 0;
	}
`;
export const ButtonFlex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
export const MenuItemWrap = styled.div`
	width: 100%;
	padding: 0.375rem 1.5rem;
`;
