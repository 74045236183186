import api from './api';
const UserService = {
	setToken: (token: string | null) => {
		if (token) {
			localStorage.setItem('token', token);
			api.defaults.headers.authorization = `JWT ${token}`;
		}
	},
	setUser: (user: any) => {
		localStorage.setItem('user', JSON.stringify(user));
		return true;
	},
	getUser() {
		let response = localStorage.getItem('user');
		if (response) {
			return JSON.parse(response);
		}
		return null;
	},
	getRole() {
		let response = localStorage.getItem('user');
		if (response) {
			return JSON.parse(response).roleId.name;
		}
		return null;
	},
	getToken: () => {
		return localStorage.getItem('token');
	},
	getTokenMail: () => {
		return localStorage.getItem('tokenMail');
	},
	isLogged: () => {
		const token = localStorage.getItem('token');
		if (token) {
			api.defaults.headers.authorization = `JWT ${token}`;
			return true;
		} else {
			return false;
		}
	},
	logOut: async () => {
		localStorage.clear();
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		delete api.defaults.headers.authorization;
		window.location.replace('/login');
		return true;
	},
	isWebLiveMopnitorinVisible: () => {
		let user = UserService.getUser();
		return user.userType === 'web' || user.roleId.name === 'root';
	},
	isApiLiveMopnitorinVisible: () => {
		let user = UserService.getUser();
		return user.userType === 'api' || user.roleId.name === 'root';
	},
	isIvrLiveMopnitorinVisible: () => {
		let user = UserService.getUser();
		return user.userType === 'ivr' || user.roleId.name === 'root';
	},
};
export default UserService;
