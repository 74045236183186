import { useDataTable } from 'hooks/useDataTable';
import { useState } from 'react';
import { Grid } from 'common/containers';
import DataTable from 'common/dataTable';
import Options from './options';
import { useIntl } from 'react-intl';
import Search from 'common/tableFilters/search';
const ProductsTable = () => {
	const { formatMessage } = useIntl();
	const [refresh, setRefresh] = useState(1);
	const {
		response,
		isLoading,
		totalDocs,
		perPage,
		handlePageChange,
		handlePerRowsChange,
		search,
		setSearch,
		showHidden,
		setShowHidden,
	} = useDataTable('products', [{ name: null, value: null }], refresh);
	const columns = [
		{
			name: 'nombre',
			selector: (row: any) => row.name,
			sortable: true,
			wrap: true,
		},
		{
			name: 'Sku',
			selector: (row: any) => row.sku,
			sortable: true,
			wrap: true,
		},
		{
			name: 'Inventario',
			selector: (row: any) => row.inventory,
			sortable: true,
			wrap: true,
		},
		{
			name: 'Costo real',
			selector: (row: any) => row.realCost,
			sortable: true,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.options' }),
			cell: (row: any) => {
				return (
					<Options
						deleteItem={`/products/${row._id}`}
						edit={`/products/edit/${row._id}`}
						refresh={setRefresh}
					/>
				);
			},
		},
	];

	return (
		<>
			<Grid
				$columns={2}
				$separator="1rem">
				<Search
					search={search}
					setSearch={setSearch}
				/>
			</Grid>
			<DataTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				pagination
				paginationServer
				paginationTotalRows={totalDocs}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				paginationPerPage={perPage}
				toggleHidden={false}
				showHidden={showHidden}
				setShowHidden={setShowHidden}
			/>
		</>
	);
};
export default ProductsTable;
