import { Outlet } from 'react-router-dom';
import Header from './header';
import Main from './main';
import SideNav from './sidenav';
const Layout = () => {
	const token = localStorage.getItem('token');
	if (!token) {
		window.location.href = '/login';
	}
	return (
		<>
			<Header />
			<SideNav />
			<Main>
				<Outlet />
			</Main>
		</>
	);
};
export default Layout;
