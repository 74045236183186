import styled from 'styled-components';

export const Wrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	h2 {
		margin-bottom: 0;
		font-weight: 400;
		font-size: 0.8rem;
		color: ${props => props.theme.contentFontColor};
		margin-right: 1em;
	}
`;
export const MenuItemWrap = styled.div`
	width: 100%;
	padding: 0.375rem 1.5rem;
`;
