import styled from 'styled-components';
import { MenuItem } from '@szhsin/react-menu';
export const Wrap = styled.div`
	cursor: pointer;
	width: 100%;
	margin-left: 1rem;
	.szh-menu__item {
		padding: 0;
	}
`;
export const Item = styled(MenuItem)``;

export const MenuItemWrap = styled.div`
	width: 100%;
	padding: 0.375rem 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	i {
		font-size: 0.9rem;
	}
`;
export const MenuItemWrapIcon = styled.div`
	width: 100%;
	padding: 0.575rem 1rem;
	min-width: 180px;
	display: grid;
	grid-template-columns: 20px 1fr;
	align-items: center;
	i {
		font-size: 0.9rem;
	}
`;
