import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const MyButton = styled.button`
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	padding: 0.75rem 2.6rem 0.6rem 2.6rem;
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	border-radius: 50px;
	transition: all 0.2s ease-in;
	border: none;
	cursor: pointer;
	&:hover {
		color: #fff;
		background-color: ${props => props.theme.primaryHoverColor};
	}
	&:disabled {
		opacity: 0.5;
		&:hover {
			transform: scale(1);
		}
	}
`;
export const MyLink = styled(Link)`
	line-height: 1.5;
	font-weight: 700;
	letter-spacing: 0.05rem;
	padding: 0.75rem 2.6rem 0.6rem 2.6rem;
	background-color: ${props => props.theme.primaryColor};
	color: #fff;
	border-radius: 50px;
	transition: all 0.2s ease-in;
	border: none;
	cursor: pointer;
	&:hover {
		color: #fff;
		background-color: ${props => props.theme.primaryHoverColor};
	}
	&:disabled {
		opacity: 0.5;
		&:hover {
			transform: scale(1);
		}
	}
`;
