import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import 'utils/yupExtensions';
import { useIntl } from 'react-intl';
import useManageForms from 'hooks/useManageForms';
// inputs
import Input from 'components/forms/inputs/input';
import Submit from 'components/forms/inputs/submit';
const AddProductForm = () => {
	const { formatMessage } = useIntl();
	const { submit } = useManageForms();
	const formik = useFormik({
		initialValues: {
			name: '',
			sku: '',
			inventory: null,
			realCost: null,
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Este campo es obligatorio'),
			inventory: Yup.number().required('Este campo es obligatorio'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await submit(values, '/products', 'Producto añadido correctamente', '/products');
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				{/* basic fields */}
				<Grid $columns={1}>
					<Input
						nameData="name"
						placeholderData="Nombre del producto"
						typeData="text"
						label="Nombre del producto"
					/>
					<Input
						nameData="sku"
						placeholderData="SKU"
						typeData="text"
						label="SKU del producto"
					/>
					<Input
						nameData="inventory"
						placeholderData="Inventario del producto"
						typeData="number"
						label="Inventario del producto"
					/>
					<Input
						nameData="realCost"
						placeholderData="Costo del producto"
						typeData="number"
						label="Costo del producto"
					/>
				</Grid>

				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.save' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default AddProductForm;
