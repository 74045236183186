import api from 'services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import formData from 'utils/formData';
const useManageForms = () => {
	const navigate = useNavigate();
	const submit = async (values: any, url: string, message: string, redirect: string) => {
		try {
			await api.post(url, values);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};
	const check = async (values: any, url: string) => {
		try {
			let response = await api.post(url, values);
			if (response.data) {
				const resultado = confirm(
					'¿Ya existe una orden con este número en status: ' +
						response.data.orderStatusId.name +
						', deseas continuar?'
				);
				if (resultado) {
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		} catch (err) {
			toast.error('Error');
		}
	};
	const submitBlacklist = async (values: any, url: string, message: string) => {
		try {
			const res = await api.post(url, values);
			const { _id } = res.data;
			toast.success(message);
			navigate(`/blacklists/edit/${_id}`);
		} catch (err) {
			toast.error('Error');
		}
	};
	const submitFormData = async (values: any, url: string, message: string, redirect: string) => {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		const data = formData(values);
		try {
			await api.post(url, data, config);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};
	const submitFormDataNoRedirect = async (values: any, url: string, message: string) => {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		const data = formData(values);
		try {
			let response = await api.post(url, data, config);
			toast.success(message);
			return response.data;
		} catch (err) {
			toast.error('Error');
			return null;
		}
	};
	const submitContactFile = async (file: any, url: string) => {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		const formData = new FormData();
		formData.append('contactsFile', file);
		try {
			let response = await api.post(url, formData, config);
			return response.data;
		} catch (err) {
			toast.error('Lo sentimos ha ocurrido un error, intenta de nuevo.');
		}
	};
	const postCall = async (values: any, url: string) => {
		try {
			let response = await api.post(url, values);
			return response.data;
		} catch (err) {
			toast.error('Lo sentimos ha ocurrido un error, intenta de nuevo.');
			return null;
		}
	};
	const editFormData = async (values: any, url: string, message: string, redirect: string) => {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		const data = formData(values);
		try {
			await api.patch(url, data, config);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};
	const edit = async (values: any, url: string, message: string, redirect: string) => {
		try {
			await api.patch(url, values);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};
	const editMany = async (values: any, url: string, message: string, redirect: string) => {
		try {
			await api.put(url, values);
			toast.success(message);
			navigate(redirect);
		} catch (err) {
			toast.error('Error');
		}
	};
	const addCredits = async (values: any, url: string) => {
		try {
			const res = await api.patch(url, values);
			return res.data;
		} catch (err) {
			toast.error('Error');
		}
	};
	const manageCampaignsStatus = async (option: string, rows: any[], message: string) => {
		try {
			let sendData: { campaignId: string; data: { statusCampaign: string } }[] = [];
			rows.map(row => {
				let info = {
					campaignId: row._id,
					data: {
						statusCampaign: option,
					},
				};
				sendData.push(info);
			});
			const res = await api.patch('/campaigns/update-many', { items: sendData });
			toast.success(message);
			return res.data;
		} catch (err) {
			toast.error('Error');
		}
	};
	return {
		submit,
		edit,
		addCredits,
		submitFormData,
		submitBlacklist,
		editFormData,
		manageCampaignsStatus,
		submitContactFile,
		submitFormDataNoRedirect,
		postCall,
		editMany,
		check,
	};
};
export default useManageForms;
