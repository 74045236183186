import { Wrap } from './styles';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
export interface PropsInterface {
	count?: number;
}

const Sekeleton = (props: PropsInterface) => {
	const { count } = props;
	const theme = useContext(ThemeContext);

	const defaultTheme = {
		body: 'default-body-color',
		background: 'default-background-color',
	};

	return (
		<SkeletonTheme
			baseColor={theme?.body || defaultTheme.body}
			highlightColor={theme?.background || defaultTheme.background}>
			<Wrap>
				<Skeleton count={count} />
			</Wrap>
		</SkeletonTheme>
	);
};

export default Sekeleton;
