import { Spinner } from './styles';
const LoadingSmall = (props: any) => {
	return (
		<Spinner {...props}>
			<div className="bounce1"></div>
			<div className="bounce2"></div>
			<div className="bounce3"></div>
		</Spinner>
	);
};

export default LoadingSmall;
