import { useState, useEffect, useCallback } from 'react';
import api from 'services/api';
import { toast } from 'react-toastify';
const useFetchData = (url: string, refresh?: number) => {
	const [isLoading, setIsLoading] = useState(true);
	const [response, setResponse] = useState<any>([]);

	const fetchData = useCallback(async () => {
		try {
			setIsLoading(true);
			let response = await api.get<any>(url);
			setResponse(response.data);
			setIsLoading(false);
		} catch (err) {
			toast.error('Error al cargar los datos');
		}
	}, [url, refresh]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);
	return {
		isLoading,
		response,
	};
};

export default useFetchData;
