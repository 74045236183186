import styled from 'styled-components';
import Select from 'react-select';

interface WrapProps {
	isDisabled?: boolean;
}
export const Wrap = styled.div<WrapProps>`
	height: 49px !important;
	position: relative;
	${props => props.isDisabled && `opacity: 25%;`}
	label {
		display: inline-block;
		padding-left: 5px;
		padding: 3px 5px;
		z-index: 8888;
		color: ${props => props.theme.fontColor};
		position: absolute;
		top: -10px;
		background-color: ${props => props.theme.background};
		left: 10px;
	}
`;

export const MyStyledSelect = styled(Select)`
	.mySelect__control {
		border-color: ${props => props.theme.separatorColor};
		background-color: ${props => props.theme.background};
		height: 49px !important;
		transition: none !important;
		box-shadow: none !important;
		&:hover {
			border-color: ${props => props.theme.separatorColor}!important;
			outline: none !important;
			box-shadow: 'none' !important;
		}
	}
	.mySelect__value-container {
		color: ${props => props.theme.fontColor};
	}
	.mySelect__menu {
		background-color: ${props => props.theme.separatorColor};
		position: absolute;
		z-index: 9999 !important;
		margin-top: 1px;
	}
	.mySelect__option {
		background-color: transparent;
		color: ${props => props.theme.fontColor};
		&:hover,
		&:active {
			background-color: ${props => props.theme.body};
		}
		&:active {
			background-color: ${props => props.theme.body};
		}
		&.mySelect__option--is-selected {
			background-color: ${props => props.theme.body};
		}
	}
	.mySelect__single-value {
		color: ${props => props.theme.fontColor};
	}
	&:focus {
		outline: none;
	}
	&.error {
		.mySelect__control {
			border-color: red;
			&:hover {
				border-color: red;
			}
		}
	}
`;
export const MyError = styled.div`
	color: red;
	padding: 0.3em;
	position: absolute;
	top: 48px;
	font-size: 12px;
	z-index: 9996;
`;
