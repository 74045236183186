/* eslint-disable */
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
export const Wrap = styled.div`
	margin: 1rem 0;
	display: flex;
	flex-direction: column;
	color: ${props => props.theme.fontColor}!important;
	position: relative;
	.rdt_TableHeader {
		display: none !important;
	}
	.rdt_Pagination {
		background-color: transparent !important;
		color: ${props => props.theme.fontColor}!important;
		border: none !important;
		margin-bottom: 1em;
	}
	#pagination-first-page,
	#pagination-previous-page,
	#pagination-next-page,
	#pagination-last-page {
		color: ${props => props.theme.fontColor};
		fill: ${props => props.theme.fontColor};
		&:disabled {
			opacity: 0.1;
		}
	}
	.rdt_TableHeader {
		display: none !important;
		order: 1;
	}
	.celdas {
		order: 2;
	}
`;
export const MyTable = styled(DataTable)`
	.rdt_Table {
		background-color: transparent;
		color: ${props => props.theme.fontColor}!important;
	}
	.rdt_TableHeadRow {
		background: transparent;
		color: ${props => props.theme.fontColor}!important;
		border-bottom-color: ${props => props.theme.separatorColor}!important;
	}
	.rdt_TableCol {
		color: ${props => props.theme.fontColor}!important;
	}
	.rdt_TableRow {
		/* background: transparent; */
		color: ${props => props.theme.fontColor}!important;
		border-bottom-color: ${props => props.theme.separatorColor}!important;
	}
	.rdt_TableCell {
		background: transparent;
		color: ${props => props.theme.fontColor}!important;
		padding: 1em;
	}
	.rdt_Table {
		div {
			color: ${props => props.theme.fontColor}!important;
		}
	}
	.rdt_Table + div {
		div {
			background: transparent;
			color: ${props => props.theme.fontColor}!important;
		}
	}
`;
export const ToggleHidden = styled.div`
	position: absolute;
	top: 20px;
	left: 2px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: flex;
	align-items: center;
	input[type='checkbox'] {
		appearance: none;
		cursor: pointer;
		margin: 0 10px 0 0;
		font: inherit;
		color: ${props => props.theme.separatorColor};
		width: 20px;
		height: 20px;
		border: 0.15em solid ${props => props.theme.separatorColor};
		border-radius: 0.15em;
		transform: translateY(-0.075em);
		display: grid;
		place-content: center;
	}

	input[type='checkbox']::before {
		content: '';
		cursor: pointer;
		width: 0.65em;
		height: 0.65em;
		clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
		transform: scale(0);
		transform-origin: bottom left;
		transition: 120ms transform ease-in-out;
		box-shadow: inset 1em 1em ${props => props.theme.fontColor};
		background-color: ${props => props.theme.primaryColor};
	}
	input[type='checkbox']:checked::before {
		transform: scale(1);
		cursor: pointer;
	}
	label {
		cursor: pointer;
	}
`;
