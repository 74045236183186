import { Wrap, MyStyledSelect, MyError } from './styles';
import { useFormikContext, Field, FieldProps } from 'formik';
interface SelectProps {
	name: string;
	options: Array<{ value: string | number; label: string }>;
	placeholder?: string;
	isDisabled?: boolean;
}

const Layout = (props: SelectProps) => {
	const { name, options, placeholder, isDisabled = false } = props;
	const { setFieldValue } = useFormikContext();
	const handleChange = (e: any) => {
		setFieldValue(name, e.value);
	};
	return (
		<Wrap>
			<Field name={name}>
				{(formik: FieldProps) => {
					return (
						<>
							<label>{placeholder}</label>
							<MyStyledSelect
								defaultValue={
									options[options.map(option => option.value).indexOf(formik.field.value)]
								}
								placeholder={placeholder || 'Selecciona una opción'}
								isSearchable={true}
								onChange={handleChange}
								isDisabled={isDisabled}
								classNamePrefix={'mySelect'}
								className={formik.meta.touched && formik.meta.error ? 'error' : ''}
								noOptionsMessage={() => 'No hay opciones'}
								options={options}
							/>
							{formik.meta.touched && formik.meta.error && <MyError>{formik.meta.error}</MyError>}
						</>
					);
				}}
			</Field>
		</Wrap>
	);
};

export default Layout;
