import styled from 'styled-components';

export const Wrap = styled.div`
	width: 120px;
	height: calc(100vh - 70px);
	border-radius: 0 0.75rem 0.75rem 0;
	overflow-y: auto;
	position: fixed;
	top: 70px;
	left: -120px;
	z-index: 1029;
	transition: left 0.3s ease-in-out;
	background-color: ${props => props.theme.background};
	${props => {
		if (props.theme.name === 'light') {
			return 'box-shadow: 0 3px 30px rgba(0,0,0,.1),0 3px 20px rgba(0,0,0,.1);';
		} else {
			return 'box-shadow: 0 3px 30px rgba(0,0,0,.3),0 3px 24px rgba(0,0,0,.3);';
		}
	}}

	&.open {
		left: 0;
	}
`;
