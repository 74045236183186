import { MainTitle } from 'common/text';
import BackButton from 'common/buttons/back';
import { Content } from 'common/containers';
import AddProductForm from 'components/forms/products/add';
const UserAdd = () => {
	return (
		<>
			<MainTitle>
				<h1>Añadir producto</h1>
				<BackButton />
			</MainTitle>
			<Content>
				<AddProductForm />
			</Content>
		</>
	);
};
export default UserAdd;
