import { useState } from 'react';
import { MainTitle } from 'common/text';
import { Content, Grid } from 'common/containers';
import DashboardTable from 'components/tables/dashboard';
import FilterSelect from 'common/tableFilters/select';
import RangeDateFilter from 'common/tableFilters/dateRangeV2';
import moment from 'moment';
import Charts from 'components/charts';
import MultiselectFetchFilter from 'common/tableFilters/multiselectFetch';
const Dashboard = () => {
	const [businessUnit, setBusinessUnit] = useState('');
	const [initialDate, setInitialDate] = useState(moment().format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [orderStatusIds, setOrderStatusIds] = useState<any>([]);
	const [createdIn, setCreatedIn] = useState('byCreationDate');
	return (
		<>
			<MainTitle>
				<h1>Dashboard</h1>
			</MainTitle>
			<Content $margin="2rem">
				<Grid $columns={1}>
					<RangeDateFilter
						initialDate={initialDate}
						setInitialDate={setInitialDate}
						endDate={endDate}
						setEndDate={setEndDate}
					/>
				</Grid>
				<Grid
					$columns={1}
					$separator="1rem">
					<MultiselectFetchFilter
						url="order-status"
						value={orderStatusIds}
						onChange={setOrderStatusIds}
						searchedValue="_id"
						searchedLabel="name"
						placeholder="Filtrar por status"
					/>
				</Grid>
				<Grid
					$columns={2}
					$separator="1rem">
					<FilterSelect
						onChange={setBusinessUnit}
						value={businessUnit}
						options={[
							{ value: '', label: 'Todas' },
							{ value: 'Keralazzio', label: 'Keralazzio' },
							{ value: 'Nanoplastec', label: 'Nanoplastec' },
						]}
						placeholder="Filtrar por Unidad de negocio"
					/>
					<FilterSelect
						onChange={setCreatedIn}
						value={createdIn}
						options={[
							{ value: 'byCreationDate', label: 'Por fecha de creación' },
							{ value: 'byPaymentDate', label: 'Por fecha de pago' },
						]}
						placeholder="Filtrar por tipo de fecha"
					/>
				</Grid>
			</Content>
			<Content $margin="2rem">
				<DashboardTable
					initialDate={initialDate}
					endDate={endDate}
					businessUnit={businessUnit}
					orderStatusIds={orderStatusIds}
					createdIn={createdIn}
				/>
			</Content>
			<Charts
				initialDate={initialDate}
				endDate={endDate}
				businessUnit={businessUnit}
				orderStatusIds={orderStatusIds}
				createdIn={createdIn}
			/>
		</>
	);
};
export default Dashboard;
