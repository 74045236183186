import { Wrap } from './styles';
import { toggleFullScreen } from 'utils/screenFunctions';
const FullScreenButton = () => {
	return (
		<Wrap onClick={toggleFullScreen}>
			<i className="material-icons-outlined">fullscreen</i>
		</Wrap>
	);
};
export default FullScreenButton;
