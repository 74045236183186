import { useContext } from 'react';
import { GlobalsContext } from 'context/globals';
import { Wrap } from './styles';
const Hamburger = () => {
	const {
		state: { menuStatus },
		dispatch,
	} = useContext(GlobalsContext);
	const openNav = () => {
		dispatch({ menuStatus: !menuStatus });
	};
	return (
		<Wrap onClick={openNav}>
			<i className="material-icons-outlined">menu</i>
		</Wrap>
	);
};
export default Hamburger;
