import { useState } from 'react';
import { InputWrap } from './styles';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Grid } from 'common/containers';
interface RangeDateFilteProps {
	initialDate: string;
	setInitialDate: React.Dispatch<React.SetStateAction<string>>;
	endDate: string;
	setEndDate: React.Dispatch<React.SetStateAction<string>>;
}

const Date = (props: RangeDateFilteProps) => {
	const { initialDate, setInitialDate, endDate, setEndDate } = props;
	const [isInitialOpen, setIsInitialOpen] = useState(false);
	const setStart = (date: string) => {
		setInitialDate(moment(date).format('YYYY-MM-DD'));
		setIsInitialOpen(!isInitialOpen);
	};
	const setEnd = (date: string) => {
		setEndDate(moment(date).format('YYYY-MM-DD'));
		setIsInitialOpen(!isInitialOpen);
	};
	return (
		<>
			<Grid $columns={2}>
				<InputWrap>
					<label>Fecha inicial</label>
					<DatePicker
						value={initialDate}
						selectsStart
						startDate={moment(initialDate).toDate()}
						endDate={moment(endDate).toDate()}
						maxDate={moment(endDate).toDate()}
						onChange={(date: Date | null) => {
							if (date) {
								setStart(date.toISOString());
							}
						}}
					/>
				</InputWrap>
				<InputWrap>
					<label>Fecha final</label>
					<DatePicker
						value={endDate}
						selectsEnd
						minDate={moment(initialDate).toDate()}
						startDate={moment(initialDate).toDate()}
						endDate={moment(endDate).toDate()}
						maxDate={moment().toDate()}
						onChange={(date: Date | null) => {
							if (date) {
								setEnd(date.toISOString());
							}
						}}
					/>
				</InputWrap>
			</Grid>
		</>
	);
};

export default Date;
