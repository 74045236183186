import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrap = styled.main`
	background-color: ${props => props.theme.body};
	color: ${props => props.theme.fontColor};
	padding-top: 100px;
	min-height: calc(100vh);
	padding: 120px 5% 50px 5%;
	transition: padding-left 0.3s ease-in-out;
	&.open {
		padding-left: calc(5% + 120px);
	}
`;
export const AnimatedWrap = styled(motion.div)``;
