import userService from 'services/userService';
import AvatarComponent from 'react-avatar';
const Avatar = () => {
	let user = userService.getUser();
	return (
		<>
			<h2>{user.name}</h2>
			<AvatarComponent
				size="40"
				round={true}
				color="#bdbdbd"
				fgColor="#fafafa"
				name={user.name}
			/>
		</>
	);
};
export default Avatar;
