import { MainTitle } from 'common/text';
import { Content } from 'common/containers';
import AddButton from 'common/buttons/add';
import OrderStatusOrderList from 'components/ordererLists/orderStatus';

const OrderStatus = () => {
	return (
		<>
			<MainTitle>
				<h1>Estatus de ordenes</h1>
				<AddButton url={`/order-status/add`} />
			</MainTitle>
			<Content>
				<OrderStatusOrderList />
			</Content>
		</>
	);
};
export default OrderStatus;
