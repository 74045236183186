import styled from 'styled-components';
interface IProps {
	disabled?: boolean;
	$icon?: string;
	$isSpinner?: boolean;
	onClick?: any;
}
export const Button = styled.button<IProps>`
	width: 35px;
	height: 35px;
	cursor: pointer;
	border: none;
	outline: none;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${({ theme }) => theme.primaryColor};
	transition: all 0.3s ease-in-out;
	i {
		font-size: 1.2rem;
		color: #fff;
		line-height: 1rem;
		transition: transform 0.3s ease-in-out;
	}
	${props => {
		if (props.$isSpinner === true || props.$isSpinner === undefined) {
			return `
      &:hover {
        background: ${props.theme.primaryHoverColor};
        i {
          transform: rotate(360deg);
        }
      }
    `;
		}
	}}
`;
