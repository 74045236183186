import { Wrap, Nav } from './styles';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Input from 'components/forms/inputs/input';
import { Grid } from 'common/containers';
import Button from 'common/button';
import LoadingSmall from 'common/loadingSmall';
import { useLogin } from 'hooks/useLogin';
const LoginForm = () => {
	const { performLogin } = useLogin();
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validationSchema: Yup.object({
			username: Yup.string().required('Este campo es obligatorio'),
			password: Yup.string().required('Este campo es obligatorio'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await performLogin(values);
			setSubmitting(false);
		},
	});
	return (
		<Wrap>
			<FormikProvider value={formik}>
				<form action="#">
					<Grid $columns={1}>
						<Input
							nameData="username"
							typeData="text"
							label="Usuario"
						/>
						<Input
							nameData="password"
							typeData="password"
							label="Contraseña"
						/>
					</Grid>
					<Nav>
						{/* <Link to="/forgot">Olvidaste tu contraseña</Link> */}
						<div></div>
						<Button
							type="submit"
							onClick={formik.handleSubmit}
							disabled={formik.isSubmitting}>
							{formik.isSubmitting ? <LoadingSmall /> : 'Iniciar sesión'}
						</Button>
					</Nav>
				</form>
			</FormikProvider>
		</Wrap>
	);
};

export default LoginForm;
