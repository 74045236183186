import { Menu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Wrap } from './styles';

export interface PropsInterface {
	children: React.ReactNode;
}

const MenuOptions = (props: PropsInterface) => {
	const { children } = props;
	return (
		<Wrap>
			<Menu
				menuButton={<i className="material-icons-outlined">more_vert</i>}
				transition
				direction="left"
				align="center"
				position="auto"
				arrow={true}>
				{children}
			</Menu>
		</Wrap>
	);
};

export default MenuOptions;
