let routes = [
	{
		id: '1',
		url: '/',
		icon: 'dashboard',
		title: 'Dashboard',
		roles: ['root'],
	},
	{
		id: '2',
		url: '/orders',
		icon: 'storefront',
		title: 'Ordenes',
		roles: ['root'],
	},
	{
		id: '3',
		url: '/products',
		icon: 'person',
		title: 'Productos',
		roles: ['root'],
	},
	{
		id: '4',
		url: '/order-status',
		icon: 'list',
		title: 'Estatus de orden',
		roles: ['root'],
	},
	{
		id: '5',
		url: '/payment-methods',
		icon: 'payments',
		title: 'Tarjetas',
		roles: ['root'],
	},
];
export default routes;
