import { MyButton } from './styles';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
interface IProps {
	url: string;
}
const AddButton = (props: IProps) => {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();
	const { url } = props;
	return (
		<MyButton onClick={() => navigate(url)}>
			<span>{formatMessage({ id: 'commons.add' })}</span>
		</MyButton>
	);
};
export default AddButton;
