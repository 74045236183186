import styled from 'styled-components';

interface StatusColorProps {
	color: string;
}
export const StatusColor = styled.div<StatusColorProps>`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: ${(props: { color: string }) => props.color};
`;
