import { Menu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Wrap, ButtonFlex } from './styles';
import { Fragment } from 'react';
export interface PropsInterface {
	children: React.ReactNode;
	button?: any;
}
const MenuWrap = (props: PropsInterface) => {
	const { children, button } = props;
	const RenderButton = button ? button : Fragment;
	return (
		<Wrap>
			<Menu
				menuButton={
					<ButtonFlex>
						<RenderButton />
						<i className="material-icons-outlined">keyboard_arrow_down</i>
					</ButtonFlex>
				}
				transition
				direction="bottom"
				align="end"
				position="auto"
				arrow={true}>
				{children}
			</Menu>
		</Wrap>
	);
};

export default MenuWrap;
