import styled from 'styled-components';

export const Wrap = styled.form`
	padding: 2em 0;
`;
export const Nav = styled.div`
	width: 100%;
	margin-top: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	a {
		color: inherit;
	}
`;
