import { Wrap } from './styles';
import routes from 'utils/navigationRoutes';
import UserService from 'services/userService';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
const SideNav = () => {
	const user = UserService.getUser();
	const { formatMessage } = useIntl();
	const userRole = user.roleId.name;
	const userType = user.userType;
	return (
		<Wrap>
			{routes.map(route => {
				if (userType === 'ivr' && userRole !== 'root') {
					delete routes[3];
					delete routes[5];
				} else if (userType === 'web' && userRole !== 'root') {
					delete routes[4];
					delete routes[5];
				} else if (userType === 'api' && userRole !== 'root') {
					delete routes[3];
					delete routes[4];
				}
				if (route && route.roles.includes(userRole)) {
					return (
						<li key={route.id}>
							<NavLink to={route.url}>
								<i className="material-icons-outlined">{route.icon}</i>
								{route.title}
							</NavLink>
						</li>
					);
				}
			})}
		</Wrap>
	);
};
export default SideNav;
