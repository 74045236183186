/* eslint-disable */
import React from 'react';
import { Wrap, MyTable, ToggleHidden } from './styles';
import Skeleton from '../skeleton';
import { useIntl } from 'react-intl';
import conditionalRowStyles from 'utils/conditionalRowStyles';
interface PropsInterface extends React.ComponentProps<typeof MyTable> {
	toggleHidden?: boolean;
	showHidden?: boolean;
	setShowHidden?: any;
}
const DataTable = (props: PropsInterface) => {
	const { formatMessage } = useIntl();
	return (
		<Wrap>
			{props.toggleHidden && (
				<ToggleHidden>
					<input
						type="checkbox"
						id="toggleHidden"
						onChange={() => props.setShowHidden && props.setShowHidden(!props.showHidden)}
						checked={!props.showHidden}
					/>
					<label htmlFor="toggleHidden">{formatMessage({ id: 'table.showInactive' })}</label>
				</ToggleHidden>
			)}
			<MyTable
				{...props}
				className="celdas"
				progressComponent={
					<div style={{ width: '100%' }}>
						<Skeleton count={15} />
					</div>
				}
				conditionalRowStyles={conditionalRowStyles}
				noDataComponent={<div>{formatMessage({ id: 'commons.noData' })}</div>}
				paginationComponentOptions={{
					rowsPerPageText: formatMessage({ id: 'commons.rowsPerPage' }),
					rangeSeparatorText: formatMessage({ id: 'commons.of' }),
				}}
			/>
		</Wrap>
	);
};

export default DataTable;
