import { useCharts } from 'hooks/useCharts';
import { Grid, Content } from 'common/containers';
import Skeleton from 'common/skeleton';
import { ResponsivePie } from '@nivo/pie';
import { Wrap } from './styles';
import { formatNumber } from 'utils/formatData';
interface ExtraParams {
	initialDate: string;
	endDate: string;
	businessUnit: string;
	orderStatusIds: any[];
	createdIn: string;
}
const Charts = (props: ExtraParams) => {
	const { initialDate, endDate, businessUnit, orderStatusIds, createdIn } = props;
	const { response, isLoading } = useCharts(
		'reports/chart',
		[
			{ name: 'initialDate', value: initialDate },
			{ name: 'endDate', value: endDate },
			{ name: 'businessUnit', value: businessUnit },
			{ name: 'createdIn', value: createdIn },
			{ name: 'orderStatusId', value: orderStatusIds },
		],
		0
	);
	return (
		<Grid
			$columns={2}
			$separator="1rem">
			<Content>
				<h2>Productos vendidos:</h2>
				<Wrap>
					{isLoading && <Skeleton count={16} />}
					{!isLoading && (
						<ResponsivePie
							data={response.products}
							margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
							innerRadius={0.5}
							padAngle={0.7}
							cornerRadius={3}
							activeOuterRadiusOffset={8}
							borderWidth={1}
							borderColor={{
								from: 'color',
								modifiers: [['darker', 0.2]],
							}}
							arcLinkLabelsSkipAngle={10}
							arcLinkLabelsTextColor="#333333"
							arcLinkLabelsThickness={2}
							arcLinkLabelsColor={{ from: 'color' }}
							arcLabelsSkipAngle={10}
							arcLabelsTextColor={{
								from: 'color',
								modifiers: [['darker', 2]],
							}}
						/>
					)}
				</Wrap>
			</Content>
			<div>
				<Content $margin="1rem">
					<h2>Total de ventas en periodo:</h2>
					<h1>${formatNumber(response.totalSales)}</h1>
				</Content>
				<Content $margin="1rem">
					<h2>Total de ganancia en periodo:</h2>
					<h1>${formatNumber(response.totalSales - response.totalRealCost)}</h1>
				</Content>
				<Content>
					<h2>Total de costos de producto:</h2>
					<h1>${formatNumber(response.totalRealCost)}</h1>
				</Content>
			</div>
		</Grid>
	);
};
export default Charts;
