import { useDataTableDash } from 'hooks/useDataTableDash';
import DataTable from 'common/dataTable';
import moment from 'moment-timezone';
import { formatNumber } from 'utils/formatData';

interface ExtraParams {
	initialDate: string;
	endDate: string;
	businessUnit: string;
	orderStatusIds: any[];
	createdIn: string;
}
const OrderStatusTable = (props: ExtraParams) => {
	const { initialDate, endDate, businessUnit, orderStatusIds, createdIn } = props;
	const {
		response,
		isLoading,
		totalDocs,
		perPage,
		handlePageChange,
		handlePerRowsChange,
		showHidden,
		setShowHidden,
	} = useDataTableDash(
		'reports',
		[
			{ name: 'initialDate', value: initialDate },
			{ name: 'endDate', value: endDate },
			{ name: 'businessUnit', value: businessUnit },
			{ name: 'createdIn', value: createdIn },
			{ name: 'orderStatusId', value: orderStatusIds },
		],
		0
	);
	const columns = [
		{
			name: 'Fecha de creación',
			selector: (row: any) => moment.tz(row.createdAt, 'America/Mexico_City').format('DD/MM/YYYY'),
			wrap: true,
		},
		{
			name: 'Fecha de pago',
			selector: (row: any) =>
				row.paymentDate
					? moment.tz(row.paymentDate, 'America/Mexico_City').format('DD/MM/YYYY')
					: 'Sin pago registrado',
			wrap: true,
		},
		{
			name: 'Cliente',
			selector: (row: any) => row.client,
			wrap: true,
		},
		{
			name: 'Products',
			selector: (row: any) => row.products.map((product: any) => product).join(', '),
			wrap: true,
			grow: 2,
		},
		{
			name: 'Total',
			selector: (row: any) => '$' + formatNumber(row.totalAmount),
			wrap: true,
		},
		{
			name: 'Utilidades',
			selector: (row: any) => '$' + formatNumber(row.totalAmount - row.totalRealCost),
			wrap: true,
		},
	];
	return (
		<>
			<DataTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				pagination
				paginationServer
				paginationTotalRows={totalDocs}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				paginationPerPage={perPage}
				toggleHidden={false}
				showHidden={showHidden}
				setShowHidden={setShowHidden}
			/>
		</>
	);
};
export default OrderStatusTable;
