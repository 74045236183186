import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import 'utils/yupExtensions';
import { useIntl } from 'react-intl';
import useManageForms from 'hooks/useManageForms';

// inputs
import Input from 'components/forms/inputs/input';
import Submit from 'components/forms/inputs/submit';
interface PropsI {
	data: any;
	id: any;
}
const EditProductForm = (props: PropsI) => {
	const { data } = props;
	const { formatMessage } = useIntl();
	const { edit } = useManageForms();
	const formik = useFormik({
		initialValues: {
			name: data.name,
			inventory: data.inventory,
			sku: data.sku,
			realCost: data.realCost,
		},
		validationSchema: Yup.object({
			name: Yup.string().required('Este campo es obligatorio'),
			inventory: Yup.number().required('Este campo es obligatorio'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await edit(values, `/products/${props.id}`, 'Producto editado correctamente', '/products');
			setSubmitting(false);
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				{/* basic fields */}
				<Grid $columns={1}>
					<Input
						nameData="name"
						placeholderData="Nombre del producto"
						typeData="text"
						label="Nombre del producto"
					/>
					<Input
						nameData="sku"
						placeholderData="SKU"
						typeData="text"
						label="SKU del producto"
					/>
					<Input
						nameData="inventory"
						placeholderData="Inventario del producto"
						typeData="number"
						label="Inventario del producto"
					/>
					<Input
						nameData="realCost"
						placeholderData="Costo del producto"
						typeData="number"
						label="Costo del producto"
					/>
				</Grid>
				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.save' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default EditProductForm;
