import Routes from './Routes';
import { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { LightTheme, DarkTheme } from './styles/colors';
import { GlobalsContext } from './context/globals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'animate.css';
function App() {
	const {
		state: { isDarkMode },
	} = useContext(GlobalsContext);
	return (
		<ThemeProvider theme={isDarkMode ? DarkTheme : LightTheme}>
			<ToastContainer
				position="top-left"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Routes />
		</ThemeProvider>
	);
}
export default App;
