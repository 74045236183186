import MenuOptions from 'common/menuOptions';
import { Item, MenuItemWrapIcon } from 'common/menuOptions/styles';
import { useIntl } from 'react-intl';
import { useOptions } from 'hooks/useOptions';
interface IOptions {
	edit: string;
	message: any;
}
const Options = (props: IOptions) => {
	const { formatMessage } = useIntl();
	const { edit, message } = props;
	const { goToNewTab } = useOptions();

	return (
		<MenuOptions>
			<Item>
				<MenuItemWrapIcon onClick={() => goToNewTab(edit)}>
					<i className="material-icons-outlined">edit</i>
					{formatMessage({ id: 'opt.edit' })}
				</MenuItemWrapIcon>
			</Item>
			<Item>
				<MenuItemWrapIcon
					onClick={() => {
						alert(
							`Hola ${message.customer}, escribimos de Keralazzio para confirmar tu pedido. Tu total a pagar es de $${message.total} por ${message.products} ¿Es correcto?`
						);
					}}>
					<i className="material-icons-outlined">message</i>
					Mostrar mensaje
				</MenuItemWrapIcon>
			</Item>
		</MenuOptions>
	);
};
export default Options;
