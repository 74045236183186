import { MainTitle } from 'common/text';
import { Content } from 'common/containers';
import PaymentMethodsTable from 'components/tables/paymentMethods';
import AddButton from 'common/buttons/add';

const PaymentMethods = () => {
	return (
		<>
			<MainTitle>
				<h1>Tarjetas</h1>
				<AddButton url={`/payment-methods/add`} />
			</MainTitle>
			<Content>
				<PaymentMethodsTable />
			</Content>
		</>
	);
};
export default PaymentMethods;
