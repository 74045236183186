import { useState, useEffect } from 'react';
import moment from 'moment';
import api from '../services/api';
import { toast } from 'react-toastify';
interface ExtraParams {
	name: any;
	value: any;
}
export const useDataTable = (
	url: string,
	extraParams: ExtraParams[] = [{ name: null, value: null }],
	refresh: number = 1
) => {
	const [isLoading, setIsLoading] = useState(true);
	const [response, setResponse] = useState<any>([]);
	const [totalDocs, setTotalDocs] = useState(0);
	const [perPage, setPerPage] = useState<any>(10);
	const [initialDate, setInitialDate] = useState(moment().format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState('');
	const [showHidden, setShowHidden] = useState(true);
	const [orderStatusIds, setOrderStatusIds] = useState<any>([]);
	const [businessUnit, setBusinessUnit] = useState<string>('');
	const createUrl = (page: number) => {
		const urlWithParams = new URL(`${process.env.REACT_APP_API}/${url}`);
		urlWithParams.searchParams.set('page', `${page}`);
		urlWithParams.searchParams.set('limit', perPage);
		urlWithParams.searchParams.set('search', search);
		urlWithParams.searchParams.set('status', showHidden ? 'true' : '');
		urlWithParams.searchParams.set('initialDate', initialDate);
		urlWithParams.searchParams.set('businessUnit', businessUnit);
		urlWithParams.searchParams.set('endDate', endDate);
		orderStatusIds.map((id: any) => {
			urlWithParams.searchParams.append('orderStatusId[]', id);
		});
		if (extraParams && extraParams.length > 0) {
			extraParams.map((param: ExtraParams) => {
				if (param.value) urlWithParams.searchParams.set(param.name, param.value);
			});
		}
		return urlWithParams;
	};
	const fetchData = async (page: number) => {
		try {
			setIsLoading(true);
			let urlParams = createUrl(page).toString();
			let response = await api.get(urlParams);
			let data = response.data.docs.map((doc: any) => {
				return {
					...doc,
					id: doc._id,
				};
			});
			setResponse(data);
			setTotalDocs(response.data.totalDocs);
			setIsLoading(false);
		} catch (err) {
			toast.error('Error al cargar los datos');
		}
	};
	const handlePageChange = (page: number) => {
		setPage(page);
		fetchData(page);
	};
	const handlePerRowsChange = async (newPerPage: number, page: number) => {
		try {
			setIsLoading(true);
			let urlParams = createUrl(page).toString();
			let response = await api.get(urlParams);
			setResponse(response.data.docs);
			setPerPage(newPerPage);
			setIsLoading(false);
		} catch (err) {
			toast.error('Error al cargar los datos');
		}
	};
	useEffect(() => {
		fetchData(page);
	}, [
		url,
		search,
		initialDate,
		endDate,
		showHidden,
		perPage,
		extraParams[0].value,
		refresh,
		orderStatusIds,
		businessUnit,
	]);
	return {
		isLoading,
		response,
		setResponse,
		totalDocs,
		perPage,
		page,
		handlePageChange,
		handlePerRowsChange,
		search,
		setSearch,
		initialDate,
		setInitialDate,
		endDate,
		setEndDate,
		showHidden,
		setShowHidden,
		fetchData,
		orderStatusIds,
		setOrderStatusIds,
		businessUnit,
		setBusinessUnit,
	};
};
