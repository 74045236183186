import styled from 'styled-components';

export const Wrap = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: #fff;
	overflow: hidden;
	box-shadow:
		0 1px 15px rgba(0, 0, 0, 0.04),
		0 1px 6px rgba(0, 0, 0, 0.04);
	display: flex;
	justify-content: space-between;
`;
export const DataWrap = styled.section`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
export const LoginWrap = styled.div`
	width: 80%;
	img {
		display: block;
		width: 100%;
		max-width: 150px;
	}
	.welcome {
		font-weight: 300;
		margin-top: 1em;
	}
`;
export const SliderWrap = styled.section`
	width: 45%;
	height: 100%;
	position: relative;
`;
