import { useContext } from 'react';
import { GlobalsContext } from 'context/globals';
import { Wrap } from './styles';
import Nav from './nav';
const SideNav = () => {
	const {
		state: { menuStatus },
	} = useContext(GlobalsContext);
	return (
		<Wrap className={`${menuStatus ? 'open' : ''}`}>
			<Nav />
		</Wrap>
	);
};
export default SideNav;
