import React, { useReducer, createContext } from 'react';

interface StateContextInterface {
	isDarkMode: boolean;
	menuStatus: boolean;
	language: string;
	checkStorage: number;
	chartsDashboardType: string;
}
const initialState = {
	isDarkMode: false,
	language: 'es',
	menuStatus: false,
	checkStorage: 0,
	chartsDashboardType: 'bar',
};
function reducer(state: StateContextInterface, action: any) {
	return { ...state, ...action };
}

const GlobalsContext = createContext<any>({});

const GlobalsProvider = (props: any) => {
	const { children } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<GlobalsContext.Provider
			value={{
				state,
				dispatch,
			}}>
			{children}
		</GlobalsContext.Provider>
	);
};

export { GlobalsContext, GlobalsProvider };
