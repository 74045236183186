import { MyButton } from './styles';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const AddButton = () => {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();
	const redirectToParent = () => {
		const currentPath = window.location.pathname;
		const segments = currentPath.split('/').filter(segment => segment !== '');
		const newPath = `/${segments[0]}`;
		navigate(newPath);
	};
	return (
		<MyButton onClick={redirectToParent}>
			<span>{formatMessage({ id: 'commons.back' })}</span>
		</MyButton>
	);
};
export default AddButton;
