import { Wrap, MyStyledSelect } from './styles';
interface SelectProps {
	onChange: React.Dispatch<React.SetStateAction<string>>;
	value: string;
	options: Array<{ value: string; label: string }>;
	placeholder?: string;
}

const Layout = (props: SelectProps) => {
	const { options, placeholder, onChange, value } = props;
	const handleChange = (e: any) => {
		onChange(e.value);
	};
	return (
		<Wrap>
			<label>{placeholder}</label>
			<MyStyledSelect
				defaultValue={options[options.map(option => option.value).indexOf(value)]}
				placeholder={placeholder || 'Selecciona una opción'}
				onChange={handleChange}
				isSearchable={true}
				classNamePrefix={'mySelect'}
				noOptionsMessage={() => 'No hay opciones'}
				options={options}
			/>
		</Wrap>
	);
};

export default Layout;
