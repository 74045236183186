import { useState } from 'react';
import { useDataTable } from 'hooks/useDataTable';
import DraggableList from 'common/draggableList';
import Sekeleton from 'common/skeleton';

const OrderStatusOrderList = () => {
	const [refresh, setRefresh] = useState(1);
	const { response, isLoading } = useDataTable(
		'order-status',
		[{ name: 'pagination', value: 'false' }],
		refresh
	);
	return (
		<div>
			{isLoading && <Sekeleton count={15} />}
			{!isLoading && (
				<DraggableList
					data={response}
					setRefresh={setRefresh}
				/>
			)}
		</div>
	);
};
export default OrderStatusOrderList;
