import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import userService from './userService';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
	let token = userService.getToken();
	const newconfig = {
		...config,
		headers: {
			...config.headers,
			authorization: `Bearer ${token}`,
		},
	};
	return newconfig;
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
	return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
	if (error.response?.status === 401) {
		userService.logOut();
	}
	// console.error(`[response error] [${JSON.stringify(error)}] acaadentro`);
	return Promise.reject(error);
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
	axiosInstance.interceptors.request.use(onRequest as any);
	axiosInstance.interceptors.response.use(onResponse, onResponseError);
	return axiosInstance;
}
