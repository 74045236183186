import { useContext } from 'react';
import { GlobalsContext } from '../../context/globals';
import { Wrap, AnimatedWrap } from './styles';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
export interface PropsInterface {
	children: React.ReactNode;
}
const Main = (props: PropsInterface) => {
	const location = useLocation();
	const { children } = props;
	const {
		state: { menuStatus },
	} = useContext(GlobalsContext);
	return (
		<AnimatePresence>
			<Wrap className={`${menuStatus ? 'open' : ''}`}>
				<AnimatedWrap
					key={location.pathname}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.55 }}>
					{children}
				</AnimatedWrap>
			</Wrap>
		</AnimatePresence>
	);
};

export default Main;
