import * as Yup from 'yup';

Yup.addMethod(Yup.string, 'ipv4', function (message = 'Número IP inválido') {
	return this.matches(/(^(\d{1,3}\.){3}(\d{1,3})$)/, {
		message,
		excludeEmptyString: true,
	}).test('ip', message, function (value) {
		return (
			value === undefined ||
			value.trim() === '' ||
			value.split('.').find(i => parseInt(i, 10) > 255) === undefined
		);
	});
});
