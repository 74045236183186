import styled from 'styled-components';
export const MyForm = styled.form`
	width: 100%;
	display: grid;
	grid-template-columns: calc(100% - 50px) 50px;
	input {
		margin-bottom: 0 !important ;
	}
`;
export const SearchButton = styled.button`
	width: 40px;
	height: 40px;
	cursor: pointer;
	overflow: hidden;
	border: none;
	border-radius: 20px;
	margin-left: 10px;
	background-color: ${props => props.theme.primaryColor};
	align-self: center;
	transition: all 0.3s ease-in-out;
	&:hover {
		background: ${({ theme }) => theme.primaryHoverColor};
	}
	i {
		color: #fff;
		font-size: 18px;
		padding-top: 4px;
		padding-left: 2px;
	}
`;
