import React, { useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import messages_es from '../translations/es.js';
import messages_en from '../translations/en.js';
import { GlobalsContext } from './globals';
interface IntlProps {
	children: React.ReactNode;
}
const Intl = (props: IntlProps) => {
	const { children } = props;
	const { state, dispatch } = useContext(GlobalsContext);
	const messages: Record<string, any> = {
		es: messages_es,
		en: messages_en,
	};
	useEffect(() => {
		let tempLanguage = localStorage.getItem('language');
		if (tempLanguage) {
			dispatch({ language: tempLanguage });
		}
	}, [dispatch]);
	return (
		<IntlProvider
			locale={state.language}
			defaultLocale={state.language}
			messages={messages[state.language]}>
			{children}
		</IntlProvider>
	);
};
export default Intl;
