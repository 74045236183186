import { Wrap, MyStyledSelect, MyError } from './styles';
import useAsyncSelect from 'hooks/useAsyncSelect';
import { Field, FieldProps, useFormikContext } from 'formik';
export interface PropsInterface {
	name: string;
	placeholder: string;
	url: string;
	searchedValue: string;
	searchedLabel: string;
	isTranslated?: boolean;
}
const OperatorsSelect = (props: PropsInterface) => {
	const { name, placeholder, url, searchedValue, searchedLabel, isTranslated } = props;
	const { setFieldValue } = useFormikContext();
	const { isLoading, options } = useAsyncSelect(url, searchedValue, searchedLabel, isTranslated);
	const handleChange = (e: any) => {
		setFieldValue(name, e.value);
	};

	return (
		<Wrap>
			<Field name={name}>
				{(formik: FieldProps) => {
					return (
						<>
							<label>{placeholder}</label>
							{isLoading && (
								<MyStyledSelect
									placeholder={placeholder}
									isSearchable={false}
									onChange={handleChange}
									classNamePrefix={`mySelect`}
									className={formik.meta.touched && formik.meta.error ? 'error' : ''}
									noOptionsMessage={() => 'No hay opciones'}
									isLoading={isLoading}
									isDisabled={isLoading}
								/>
							)}
							{!isLoading && (
								<MyStyledSelect
									defaultValue={
										options[options.map((option: any) => option.value).indexOf(formik.field.value)]
									}
									placeholder={placeholder}
									isSearchable={true}
									options={options}
									onChange={handleChange}
									classNamePrefix={`mySelect`}
									className={formik.meta.touched && formik.meta.error ? 'error' : ''}
									noOptionsMessage={() => 'No hay opciones'}
									isLoading={isLoading}
									isDisabled={isLoading}
								/>
							)}

							{formik.meta.touched && formik.meta.error && <MyError>{formik.meta.error}</MyError>}
						</>
					);
				}}
			</Field>
		</Wrap>
	);
};
export default OperatorsSelect;
