import { MainTitle } from 'common/text';
import BackButton from 'common/buttons/back';
import { Content } from 'common/containers';
import AddOrdersForm from 'components/forms/orders/add';
const UserAdd = () => {
	return (
		<>
			<MainTitle>
				<h1>Crear orden</h1>
				<BackButton />
			</MainTitle>
			<Content>
				<AddOrdersForm />
			</Content>
		</>
	);
};
export default UserAdd;
