import { Wrap, MenuItemWrap } from './styles';
import userService from 'services/userService';
import Menu from 'common/menu';
import { MenuItem } from '@szhsin/react-menu';
import { useIntl } from 'react-intl';
import Avatar from './avatar';
const AvatarMenu = () => {
	const { formatMessage } = useIntl();
	return (
		<Wrap>
			<Menu button={Avatar}>
				<MenuItem>
					<MenuItemWrap onClick={() => userService.logOut()}>
						{formatMessage({ id: 'logout.title' })}
					</MenuItemWrap>
				</MenuItem>
			</Menu>
		</Wrap>
	);
};
export default AvatarMenu;
