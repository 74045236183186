import axios from 'axios';
import { setupInterceptorsTo } from './interceptors';
const api = axios.create({
	baseURL: `${process.env.REACT_APP_API}`,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
});
setupInterceptorsTo(api);

export default api;
