import { InputWrap, MyStyledInput, MyError } from './styles';
import { Field, FieldProps } from 'formik';

export interface InputProps {
	nameData: string;
	placeholderData?: string;
	typeData: string;
	label?: string;
	isDisabled?: boolean;
	maxlength?: number;
	quitErrorData?: boolean;
}

const Input = (props: InputProps) => {
	const { nameData, placeholderData, typeData, label, isDisabled, maxlength } = props;
	return (
		<>
			<Field name={nameData}>
				{({ field, meta }: FieldProps) => (
					<InputWrap>
						{label && <label>{label}</label>}
						<MyStyledInput
							type={typeData}
							placeholder={placeholderData}
							className={meta.touched && meta.error ? 'error' : ''}
							disabled={isDisabled}
							maxLength={maxlength}
							{...field}
						/>
						{meta.touched && meta.error && !isDisabled && <MyError>{meta.error}</MyError>}
					</InputWrap>
				)}
			</Field>
		</>
	);
};

export default Input;
