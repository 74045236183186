import styled from 'styled-components';

export const Wrap = styled.div``;
export const InputWrap = styled.div`
	width: 100%;
	position: relative;
	label {
		display: inline-block;
		padding-left: 5px;
		padding: 3px 5px;
		color: ${props => props.theme.fontColor};
		position: absolute;
		top: -10px;
		left: 10px;
		background-color: ${props => props.theme.background};
	}
`;
export const MyStyledInput = styled.input`
	padding: 1em 0.8em;
	height: 49px !important;
	width: 100%;
	display: block;
	background: transparent !important;
	border: 1px solid ${props => props.theme.separatorColor};
	border-radius: 5px;
	outline: none;
	color: ${props => props.theme.fontColor};
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	&.error {
		border: 1px solid red;
	}
	&.error:disabled {
		border: 1px solid ${props => props.theme.separatorColor};
	}
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active,
	&:autofill,
	&:autofill:hover,
	&:autofill:focus,
	&:autofill:active {
		appearance: inherit;
		background-color: transparent !important;
		background-image: none !important;
		color: inherit !important;
		font-size: 14px;
		font-family: 'Roboto', sans-serif;
	}
	&:-internal-autofill-selected {
		appearance: inherit;
		background-color: transparent !important;
		background-image: none !important;
		color: inherit !important;
		font-size: 14px;
		font-family: 'Roboto', sans-serif;
	}
	&:disabled {
		cursor: not-allowed;
	}
	&::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		${(props: any) => {
			if (props.theme.name === 'light') {
				return `color: ${props.theme.separatorColor};`;
			} else {
				return `color: ${props.theme.fontColor};`;
			}
		}}
		opacity: 1; /* Firefox */
	}
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	/* Firefox */
	&[type='number'] {
		-moz-appearance: textfield;
	}
`;
export const MyError = styled.div`
	color: red;
	padding: 0.3em;
	position: absolute;
	top: 48px;
	font-size: 12px;
	z-index: 9996;
`;
