import { useFormik, FormikProvider } from 'formik';
import { Grid, Flex } from 'common/containers';
import * as Yup from 'yup';
import 'utils/yupExtensions';
import { useIntl } from 'react-intl';
import useManageForms from 'hooks/useManageForms';
// inputs
import Input from 'components/forms/inputs/input';
import Submit from 'components/forms/inputs/submit';
const AddPaymentMehtodsForm = () => {
	const { formatMessage } = useIntl();
	const { submit } = useManageForms();
	const formik = useFormik({
		initialValues: {
			cardNumber: '',
			bank: '',
			balance: null,
		},
		validationSchema: Yup.object({
			cardNumber: Yup.string().required('Este campo es obligatorio'),
			bank: Yup.string().required('Este campo es obligatorio'),
			balance: Yup.number().required('Este campo es obligatorio'),
		}),
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			await submit(values, '/payment-methods', 'Tarjeta añadida correctamente', '/payment-methods');
		},
	});
	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				{/* basic fields */}
				<Grid $columns={1}>
					<Input
						nameData="cardNumber"
						placeholderData="Número de tarjeta"
						typeData="text"
						label="Número de tarjeta"
					/>
					<Input
						nameData="bank"
						placeholderData="Nombre del banco"
						typeData="text"
						label="Nombre del banco"
					/>
					<Input
						nameData="balance"
						placeholderData="Balance"
						typeData="number"
						label="Balance"
					/>
				</Grid>

				<Flex $margin="2rem 0 0 0">
					<Submit
						onClick={formik.handleSubmit}
						disabled={formik.isSubmitting}>
						{formatMessage({ id: 'commons.save' })}
					</Submit>
				</Flex>
			</form>
		</FormikProvider>
	);
};
export default AddPaymentMehtodsForm;
