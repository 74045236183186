import { useFormik, FormikProvider } from 'formik';
import Input from 'components/forms/inputs/input';
import { MyForm, SearchButton } from './styles';
import { useIntl } from 'react-intl';
interface SearchProps {
	search: string;
	setSearch: any;
}
export const Search = (props: SearchProps) => {
	const { setSearch, search } = props;
	const { formatMessage } = useIntl();
	const formik = useFormik({
		initialValues: {
			search: search,
		},
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			setSearch(values.search);
			setSubmitting(false);
		},
	});
	const handleClear = () => {
		setSearch('');
		formik.setFieldValue('search', '');
	};
	return (
		<FormikProvider value={formik}>
			<MyForm onSubmit={formik.handleSubmit}>
				<Input
					nameData="search"
					label={formatMessage({ id: 'commons.filter' })}
					placeholderData={formatMessage({ id: 'commons.filter' })}
					typeData="text"
					quitErrorData={true}
				/>
				{search === '' && (
					<SearchButton type="submit">
						<i className="material-icons-outlined">search</i>
					</SearchButton>
				)}
				{search !== '' && (
					<SearchButton
						type="button"
						onClick={handleClear}>
						<i className="material-icons-outlined">clear</i>
					</SearchButton>
				)}
			</MyForm>
		</FormikProvider>
	);
};
export default Search;
