import MenuOptions from 'common/menuOptions';
import { Item, MenuItemWrapIcon } from 'common/menuOptions/styles';
import { useIntl } from 'react-intl';
import { useOptions } from 'hooks/useOptions';
interface IOptions {
	deleteItem: string;
	edit: string;
	// eslint-disable-next-line no-unused-vars
	refresh: (value: number) => void;
}
const Options = (props: IOptions) => {
	const { formatMessage } = useIntl();
	const { deleteItem, edit, refresh } = props;
	const { goTo, deleteCall } = useOptions();
	const handleDelete = async () => {
		await deleteCall(deleteItem);
		refresh(Math.random());
	};
	return (
		<MenuOptions>
			<Item>
				<MenuItemWrapIcon onClick={() => goTo(edit)}>
					<i className="material-icons-outlined">edit</i>
					{formatMessage({ id: 'opt.edit' })}
				</MenuItemWrapIcon>
			</Item>
			<Item>
				<MenuItemWrapIcon onClick={handleDelete}>
					<i className="material-icons-outlined">delete</i>
					Eliminar
				</MenuItemWrapIcon>
			</Item>
		</MenuOptions>
	);
};
export default Options;
