import styled from 'styled-components';

export const MainTitle = styled.div`
	width: 100%;
	border-bottom: 1px solid ${props => props.theme.separatorColor};
	margin-bottom: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
	h1,
	h2 {
		font-size: 1.75rem;
		margin: 0;
		padding: 0;
	}
`;

interface ISubtitle {
	$center?: string;
	$separator?: boolean;
	$margin?: string;
}
export const Subtitle = styled.h2<ISubtitle>`
	color: ${props => props.theme.textColor};
	display: flex;
	margin: ${props => (props.$margin ? props.$margin : '0')};
	justify-content: ${({ $center }) => ($center ? $center : 'left')};
	align-items: center;
	${props => {
		if (props.$separator) {
			return `
        border-bottom: 1px solid ${props.theme.separatorColor};
        padding-bottom: 10px;
      `;
		}
	}}
`;
