/* eslint-disable no-unused-vars */
import React from 'react';
import { Button } from './styles';
interface IProps {
	disabled?: boolean;
	$icon?: string;
	$isSpinner?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
const CircleButton = (props: IProps) => {
	const { $icon } = props;
	return (
		<Button {...props}>
			<i className="material-icons-outlined">{$icon}</i>
		</Button>
	);
};
export default CircleButton;
