import { MainTitle } from 'common/text';
import BackButton from 'common/buttons/back';
import { Content } from 'common/containers';
import EditPaymentMethodsForm from 'components/forms/paymentMethods/edit';
import useFetchData from 'hooks/useFetchData';
import Skeleton from 'common/skeleton';
import { useParams } from 'react-router-dom';
const PaymentMethodsEdit = () => {
	const { id } = useParams<{ id: string }>();
	const { response, isLoading } = useFetchData(`payment-methods/${id}`);
	return (
		<>
			<MainTitle>
				<h1>Editar tarjetas</h1>
				<BackButton />
			</MainTitle>
			<Content>
				{isLoading && <Skeleton count={10} />}
				{!isLoading && (
					<EditPaymentMethodsForm
						data={response}
						id={id}
					/>
				)}
			</Content>
		</>
	);
};
export default PaymentMethodsEdit;
