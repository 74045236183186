import { Wrap, MyStyledSelect } from './styles';
import useAsyncSelect from 'hooks/useAsyncSelect';
interface SelectFetchProps {
	value: string[] | number[];
	onChange: React.Dispatch<React.SetStateAction<string[]>>;
	url: string;
	searchedValue: string;
	searchedLabel: string;
	placeholder: string;
}
const SelectFetch = (props: SelectFetchProps) => {
	const { value, onChange, url, searchedValue, searchedLabel, placeholder } = props;
	const { isLoading, options } = useAsyncSelect(url, searchedValue, searchedLabel, false, false);
	const handleChange = (e: any) => {
		const valuesArray = e.map((item: any) => item.value);
		onChange(valuesArray);
	};
	return (
		<Wrap>
			<label>{placeholder}</label>
			{isLoading && (
				<MyStyledSelect
					placeholder={placeholder}
					onChange={handleChange}
					classNamePrefix={`mySelect`}
					noOptionsMessage={() => 'No hay opciones'}
					isLoading={isLoading}
					isDisabled={isLoading}
				/>
			)}
			{!isLoading && (
				<MyStyledSelect
					defaultValue={options[options.map((option: any) => option.value).indexOf(value)]}
					placeholder={placeholder}
					options={options}
					onChange={handleChange}
					classNamePrefix={`mySelect`}
					isMulti
					noOptionsMessage={() => 'No hay opciones'}
					isLoading={isLoading}
					isDisabled={isLoading}
					isSearchable={true}
				/>
			)}
		</Wrap>
	);
};
export default SelectFetch;
