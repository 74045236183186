import styled from 'styled-components';

export const Wrap = styled.div`
	padding: 10px 20px;
	background-color: #fff;
	border: 1px solid #e6e6e6;
	border-radius: 5px;
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: 60px 1fr 60px;
	align-items: center;
`;

interface DragHandleProps {
	$background?: string;
}
export const DragHandle = styled.div<DragHandleProps>`
	cursor: pointer;
	padding: 10px;
	width: 40px;
	height: 40px;
	background-color: ${({ $background }) => $background || '#f1f1f1'};
	border-radius: 5px;
	border: 1px solid #e6e6e6;
	display: flex;
	justify-content: center;
	align-items: center;
`;
