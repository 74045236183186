import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useState } from 'react';
import OrderStatusCard from 'common/cards/orderStatus';
import useManageForms from 'hooks/useManageForms';

interface DraggableListProps {
	data: any[];
	// eslint-disable-next-line no-unused-vars
	setRefresh: (value: number) => void;
}
const DraggableList = (props: DraggableListProps) => {
	const { data, setRefresh } = props;
	const { editMany } = useManageForms();
	const [items, setItems] = useState(data);

	const onDragEnd = async (result: DropResult) => {
		if (!result.destination) {
			return;
		}
		const reorderedItems = Array.from(items);
		const [movedItem] = reorderedItems.splice(result.source.index, 1);
		reorderedItems.splice(result.destination.index, 0, movedItem);

		const updatedItems = reorderedItems.map((item, index) => ({
			...item,
			order: index + 1,
		}));
		setItems(updatedItems);

		editMany(
			{ items: updatedItems },
			'/order-status/update-many',
			'Se ha guardado correctamente el orden',
			'/order-status'
		);
	};
	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{provided => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}>
							{items.map((item, index) => (
								<Draggable
									key={item._id}
									draggableId={item._id}
									index={index}>
									{provided => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}>
											<OrderStatusCard
												item={item}
												setRefresh={setRefresh}
											/>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</>
	);
};

export default DraggableList;
