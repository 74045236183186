import { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
interface ExtraParams {
	name: any;
	value: any;
}
export const useCharts = (
	url: string,
	extraParams: ExtraParams[] = [{ name: null, value: null }],
	refresh: number = 1
) => {
	const [isLoading, setIsLoading] = useState(true);
	const [response, setResponse] = useState<any>([]);
	const createUrl = () => {
		const urlWithParams = new URL(`${process.env.REACT_APP_API}/${url}`);
		if (extraParams && extraParams.length > 0) {
			extraParams.forEach((param: ExtraParams) => {
				if (param.value) {
					if (Array.isArray(param.value)) {
						param.value.forEach(item => {
							urlWithParams.searchParams.append(`${param.name}[]`, item);
						});
					} else {
						urlWithParams.searchParams.set(param.name, param.value);
					}
				}
			});
		}
		return urlWithParams;
	};
	const fetchData = async () => {
		try {
			setIsLoading(true);
			let urlParams = createUrl().toString();
			let response = await api.get(urlParams);
			setResponse(
				response?.data?.docs[0] || {
					totalSales: 0,
					totalRealCost: 0,
					products: [
						{
							id: 'Sin_venta',
							name: 'Sin venta',
							value: 1,
							totalRealCost: 1,
						},
					],
				}
			);
			setIsLoading(false);
		} catch (err) {
			toast.error('Error al cargar los datos');
		}
	};
	useEffect(() => {
		fetchData();
	}, [url, refresh, ...extraParams.map(param => param.value)]);
	return {
		isLoading,
		response,
		setResponse,
		fetchData,
	};
};
