import { useDataTable } from 'hooks/useDataTable';
import { Grid } from 'common/containers';
import DataTable from 'common/dataTable';
import Options from './options';
import { useIntl } from 'react-intl';
import Search from 'common/tableFilters/search';
import FilterSelect from 'common/tableFilters/select';
import { calculateTotalPrice, formatNumber, getProductNames } from 'utils/formatData';
import MultiselectFetchFilter from 'common/tableFilters/multiselectFetch';
import { StatusColor } from './styles';
import moment from 'moment-timezone';
const OrdersTable = () => {
	const { formatMessage } = useIntl();

	const {
		response,
		isLoading,
		totalDocs,
		perPage,
		handlePageChange,
		handlePerRowsChange,
		search,
		setSearch,
		showHidden,
		setShowHidden,
		orderStatusIds,
		setOrderStatusIds,
		businessUnit,
		setBusinessUnit,
	} = useDataTable('orders', [{ name: null, value: null }], 0);
	const columns = [
		{
			cell: (row: any) => <StatusColor color={row.orderStatus.color} />,
			wrap: true,
			width: '50px',
		},
		{
			name: 'Status',
			selector: (row: any) => row.orderStatus.name,
			wrap: true,
		},
		{
			name: 'Status de envio',
			selector: (row: any) => row.shipmentStatus || 'Sin status',
			wrap: true,
		},
		{
			name: 'Fecha de creación',
			selector: (row: any) => moment.tz(row.createdAt, 'America/Mexico_City').format('DD/MM/YYYY'),
			wrap: true,
		},
		{
			name: 'Cliente',
			selector: (row: any) => row.customer,
			wrap: true,
		},
		{
			name: 'Teléfono',
			selector: (row: any) => row.customerPhone,
			wrap: true,
		},
		{
			name: 'Guía',
			selector: (row: any) => row.carrierGuide,
			wrap: true,
		},
		{
			name: 'Productos',
			selector: (row: any) => getProductNames(row.products),
			wrap: true,
		},
		{
			name: 'Total',
			selector: (row: any) => '$' + formatNumber(calculateTotalPrice(row.products)),
			wrap: true,
		},
		{
			name: 'Comentarios',
			selector: (row: any) => row.comments,
			wrap: true,
		},
		{
			name: formatMessage({ id: 'table.options' }),
			cell: (row: any) => {
				return (
					<Options
						edit={`/orders/edit/${row._id}`}
						message={{
							customer: row.customer,
							total: formatNumber(calculateTotalPrice(row.products)),
							products: getProductNames(row.products),
						}}
					/>
				);
			},
		},
	];
	return (
		<>
			<Grid
				$columns={2}
				$separator="1rem">
				<MultiselectFetchFilter
					url="order-status"
					value={orderStatusIds}
					onChange={setOrderStatusIds}
					searchedValue="_id"
					searchedLabel="name"
					placeholder="Filtrar por status"
				/>
				<FilterSelect
					onChange={setBusinessUnit}
					value={businessUnit}
					options={[
						{ value: 'Keralazzio', label: 'Keralazzio' },
						{ value: 'Nanoplastec', label: 'Nanoplastec' },
					]}
					placeholder="Filtrar por Unidad de negocio"
				/>
			</Grid>
			<Grid
				$columns={1}
				$separator="1rem">
				<Search
					search={search}
					setSearch={setSearch}
				/>
			</Grid>
			<DataTable
				data={response}
				columns={columns}
				progressPending={isLoading}
				pagination
				paginationServer
				paginationTotalRows={totalDocs}
				onChangeRowsPerPage={handlePerRowsChange}
				onChangePage={handlePageChange}
				paginationPerPage={perPage}
				toggleHidden={false}
				showHidden={showHidden}
				setShowHidden={setShowHidden}
			/>
		</>
	);
};
export default OrdersTable;
