import { Wrap, DragHandle } from './styles';
import Options from './options';

const OrderStatusCard = (props: any) => {
	const {
		item: { name, color, _id },
		setRefresh,
	} = props;
	return (
		<Wrap>
			<DragHandle $background={color}>
				<i className="material-icons-outlined">drag_indicator</i>
			</DragHandle>
			{name}
			<Options
				refresh={setRefresh}
				deleteItem={`/order-status/${_id}`}
				edit={`/order-status/edit/${_id}`}
			/>
		</Wrap>
	);
};
export default OrderStatusCard;
