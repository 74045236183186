import Layout from 'components/account/layout';
import LoginForm from 'components/forms/login';
const Login = () => {
	return (
		<Layout>
			<LoginForm />
		</Layout>
	);
};

export default Login;
