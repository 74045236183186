import { MainTitle } from 'common/text';
import BackButton from 'common/buttons/back';
import { Content } from 'common/containers';
import AddPaymentMethodForm from 'components/forms/paymentMethods/add';
const PaymentMethodsAdd = () => {
	return (
		<>
			<MainTitle>
				<h1>Añadir tarjetas</h1>
				<BackButton />
			</MainTitle>
			<Content>
				<AddPaymentMethodForm />
			</Content>
		</>
	);
};
export default PaymentMethodsAdd;
