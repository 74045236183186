import { MyButton } from './Styles';

const Layout = (props: any) => {
	return (
		<>
			<MyButton
				disabled={props.disabled}
				{...props}>
				<i className="material-icons-outlined">delete</i>
			</MyButton>
		</>
	);
};

export default Layout;
