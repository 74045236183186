import { MainTitle } from 'common/text';
import BackButton from 'common/buttons/back';
import { Content } from 'common/containers';
import AddOrderStatusForm from 'components/forms/orderStatus/add';
const OrderStatusAdd = () => {
	return (
		<>
			<MainTitle>
				<h1>Añadir estatus de orden</h1>
				<BackButton />
			</MainTitle>
			<Content>
				<AddOrderStatusForm />
			</Content>
		</>
	);
};
export default OrderStatusAdd;
